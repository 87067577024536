import React, { useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Tokenizer from "../../../../helpers/tokenizer";
import { Typography, Row, Col } from "antd";
import { createGlobalStyle } from "styled-components";
import Header from "./header";

import { colors } from "./constants/colors";

const { Title, Paragraph } = Typography;

const GlobalStyles = createGlobalStyle`

    html, html * {
        font-family: 'Golos', serif !important
    }

    .ant-tooltip-arrow {
        color: ${colors.accent};
        display: none;
    }

    .ant-tooltip-inner {
        background-color: white;
        color: black;
        padding: 21px;
        border: 1px solid ${colors.accent};

        & * {
            color: black
        }
    }


    @media (min-width: 992px) {
        html {
            font-size: 16px !important;
        }
    }

    @media (min-width: 576px) and (max-width: 992px) {
        html {
            font-size: 15px !important;
        }
    }

    @media (max-width: 576px) {
        html {
            font-size: 14px !important;
        }
    }

    h1, h2, h3, h4, h5 {
        &.ant-typography {
            font-weight: 900;
            color: #001520;
        }
    }

    div.ant-typography[level="-2"], span.ant-typography[level="-2"] {
        & {
            font-size: 1.25rem;
            line-height: 1.25;
            color: #001520;
        }
    }

    div.ant-typography[level="-1"], span.ant-typography[level="-1"] {
        & {
            font-size: 1.125rem;
            line-height: 1.25;
            color: #001520;
        }
    }

    div.ant-typography[level="1"], span.ant-typography[level="1"] {
        & {
            font-size: 1rem;
            line-height: 1.25;
            color: #001520;
        }
    }

    div.ant-typography[level="2"], span.ant-typography[level="2"] {
        & {
            font-size: .875rem;
            line-height: 1.25;
            color: #001520;
        }
    }

    && {
    h1.ant-typography, h2.ant-typography, h3.ant-typography, h4.ant-typography, h5.ant-typography {
            &[data-margin="nomargin"] {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }

    h1.ant-typography {
        font-size: 3rem;
        color: #131C27;
        line-height: 1.05
    }
    h2.ant-typography {
        font-size: 2.25rem;
        color: #131C27;
    }
    h3.ant-typography {
        font-size: 1.875rem;
        color: #131C27;
    }
    h4.ant-typography {
        font-size: 1.5rem;
        color: #131C27;
        line-height: 1.25;
    }
    h5.ant-typography {
        font-size: 1.125rem;
        color: #131C27;
        line-height: 1.25;

        &[data-size="larger"] {
            font-size: 1.25rem
        }

        &[data-size="smaller"] {
            font-size: 1rem
        }
    }

    &&& {
        .ant-divider-vertical {
            border-left: 1px solid ${colors.grey};
            height: 1.5em
        }

        .ant-btn {
            height: 55px;
            padding-left: 30px;
            padding-right: 30px;
            border-width: 2px;
            font-weight: 400 !important;

            &[disabled], &[disabled]:focus, &[disabled]:active, &[disabled]:hover {
                background: rgba(201, 203, 206, 1);
                border-color: rgba(201, 203, 206, 1)
            }

            &-primary {
                background: ${colors.accent};
                border-color: ${colors.accent};

                &:hover {
                    background: ${colors.accent};
                    border-color: ${colors.accent};
                    filter: brightness(1.2)
                }

                &[data-height="max-content"] {
                    height: max-content;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    width: 100%;
                    line-height: 1.3
                }

                &[data-size="small"] {
                    height: 30px;
                    padding: 0 1rem
                }

                &[data-size="big"] {
                    height: 70px;
                    font-size: 1.37rem;
                    padding-left: 4.25rem;
                    padding-right: 4.25rem
                }

                &[data-size="large"] {
                    height: 95px;
                    font-size: 1.37rem;
                    padding-left: 4.25rem;
                    padding-right: 4.25rem;

                    @media (max-width: 768px) {
                        & {
                            font-size: 1.1rem; 
                            height: 80px;
                        }

                        @media (max-width: 576px) {
                            & {
                                font-size: 1rem; 
                                padding-left: unset;
                                padding-right: unset;
                                width: 100%;
                                max-width: 100%
                            }
                        }
                    }
                }
            }

            &-secondary {
                border-color: ${colors.accent};
                color: ${colors.accent};
                & * {
                    color: ${colors.accent};
                }

                &:hover {
                    color: ${colors.accent};
                    border-color: ${colors.accent};
                    filter: brightness(1.2);

                    & * {
                        color: ${colors.accent};
                    }
                }
            }
        }
        
    }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1400px;

  margin-left: auto;
  margin-right: auto;

  padding: ${({ type }) => (type === "content" ? "90px" : "0")} 80px;

  @media (max-width: 576px) {
    &&& {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  height: 100%;

  ${({ $type }) =>
    $type === "header"
      ? `
    && {
        width: 100%;
        display: flex;
        height: 100%;
        align-items: center;

        & > * {
            width: 100%;
        }
    }
  `
      : ``}
`;

export const Gap = styled.div`
  height: ${({ height }) => (height ? height : "16px")};
`;

const ErrorResult = () => {
  return (
    <Row>
      <Col span={24}>
        <Gap height={"4rem"} />
        <Row justify="center">
          <Title level={2}>Права доступа отсутствуют</Title>
        </Row>
        <Row justify="center">
          <Paragraph>
            Попробуйте войти в личный кабинет с другого аккаунта
          </Paragraph>
        </Row>
      </Col>
    </Row>
  );
};

const Layout = ({ children }) => {
  /* проверка роли пользователя */
  const { auth } = useSelector((state) => state);

  const authData = Tokenizer.decode(auth.access_token);
  const { hasura_claims = {} } = authData ? authData : {};
  const userRole = useMemo(() => {
    if (hasura_claims["x-hasura-default-role"])
      return hasura_claims["x-hasura-default-role"];

    return "";
  }, [hasura_claims]);

  return (
    <>
      <GlobalStyles />

      <Header />

      <Container type="content">
        {userRole !== "client_user" ? <ErrorResult /> : children}
      </Container>
    </>
  );
};

export default Layout;
