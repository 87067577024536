import React, { useState, useMemo, useEffect } from "react";

import Paper from "../../layout/paper";
import HandleXLSX from "./xlsx/index";
import extraFormattedIdeas from "../extra-formatted-ideas.json";

import { Typography, Modal, Pagination, Skeleton, Button } from "antd";
import { IdeasWrapper, IdeasLine, PaginationWrapper } from "./__styled";

/* Query запрос */
import { useLazyQuery } from "@apollo/client";
import { GET_LOCAL_CITY_IDEAS as getIdeas } from "../../../../../queries/5steps";

const { Title, Text } = Typography;

const CitizensIdeas = ({ context, cityIndex }) => {
  const [citizensIdeas, setCitizensIdeas] = useState(null);

  const [getLocalIdeas, { data: localIdeas }] = useLazyQuery(getIdeas, {
    context,
  });

  useEffect(() => {
    if (cityIndex || cityIndex === 0) {
      getLocalIdeas({
        variables: {
          _contains: {
            node: "node0.0",
            attachment: { variant: `answer.0.0.${cityIndex}` },
          },
        },
      });
    }
  }, [cityIndex]);

  useEffect(() => {
    if (localIdeas && (cityIndex || cityIndex === 0)) {
      let { chats = [] } = localIdeas;

      chats = [...chats];

      setCitizensIdeas(chats);
    }
  }, [localIdeas, cityIndex]);

  /* Стейт modal окна с описанием идеи */
  const [openedMessage, setOpenedMessage] = useState(false);
  const [messageContent, setMessageContent] = useState(null);

  const ideasPerPage = 50;
  const ideasAmount = useMemo(() => {
    if (citizensIdeas) {
      return Math.ceil(citizensIdeas.length / ideasPerPage);
    }

    return 0;
  }, [citizensIdeas]);

  const [currIdeasPage, setCurrIdeasPage] = useState(1);
  useEffect(() => {
    setCurrIdeasPage(1);
  }, [ideasAmount]);

  const citizenIdeas_page = useMemo(() => {
    if (citizensIdeas) {
      const start = ideasPerPage * (currIdeasPage - 1);

      return citizensIdeas.slice(start, start + ideasPerPage);
    }

    return [];
  }, [citizensIdeas, currIdeasPage, ideasAmount, ideasPerPage]);

  return (
    <>
      <Paper>
        <Title level={4}>Идеи горожан</Title>

        <Modal
          visible={openedMessage}
          footer={false}
          onCancel={() => setOpenedMessage(false)}
          maskStyle={{ opacity: 0.5 }}
        >
          {messageContent}
        </Modal>

        {citizensIdeas && citizensIdeas.length > 0 ? (
          <>
            <div style={{ marginBottom: "9px" }}>
              <b style={{ color: "#131C27" }}>
                Всего идей: {citizensIdeas.length}
              </b>
            </div>

            <IdeasWrapper key={`ideasLine:${currIdeasPage}`}>
              <IdeasLine>
                {citizenIdeas_page &&
                  citizenIdeas_page.map((item = {}, i) => {
                    const { dialogs = [] } = item;

                    const idea_data = dialogs.find((data = {}) => {
                      const { message = {} } = data;
                      const { type, node } = message;

                      if (type === "plain" && node === "node0.2") return true;
                      return false;
                    });

                    const { message: idea_message = {} } = idea_data
                      ? idea_data
                      : {};
                    const { text: idea } = idea_message;

                    return (
                      <>
                        <div
                          key={`citizen:${i}`}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOpenedMessage(true);
                            setMessageContent(
                              <>
                                <Text>{idea}</Text>
                              </>
                            );
                          }}
                        >
                          <Text
                            style={{ cursor: "pointer;" }}
                            ellipsis={{ rows: 4 }}
                          >
                            {idea}
                          </Text>
                        </div>
                      </>
                    );
                  })}
              </IdeasLine>
            </IdeasWrapper>

            <PaginationWrapper>
              <Pagination
                showSizeChanger={false}
                onChange={(e) => setCurrIdeasPage(e)}
                current={currIdeasPage}
                pageSize={ideasPerPage}
                total={citizensIdeas.length}
              />

              <HandleXLSX
                data={citizensIdeas}
                button={<Button type="primary">Скачать таблицей</Button>}
              />
            </PaginationWrapper>
          </>
        ) : citizensIdeas ? (
          <>Список идей пока пуст</>
        ) : (
          <>{<Skeleton />}</>
        )}
      </Paper>
    </>
  );
};

export default CitizensIdeas;
