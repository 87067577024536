/**
 * Internal Dependencies
 */
// SignIn
import SignInPage from "./Auth/SignIn";
import SignUpPage from "./Auth/SignUp";
import ForgotPage from "./Auth/Forgot";
import RecoveryPage from "./Auth/Recovery";

// Crowdsourcing
import RequestPage from "./Crowdfunding/5Steps/request";
import HomePage from "./Crowdfunding/5Steps/homepage/";
import ExecutorsPage from "./Crowdfunding/5Steps/executors";

// Apps

export default {
  "/sign-in": SignInPage,
  "/sign-up": SignUpPage,
  "/forgot": ForgotPage,
  "/recovery": RecoveryPage,
  "/home": HomePage,
  "/": HomePage,
  "/request": RequestPage,
  "/executors": ExecutorsPage,
  // "/": CrowdsourcingProject,
  /*"/projects": ProjectsPage,
  "/(project)?": CrowdsourcingProject,
  // "/map": CrowdsourcingMap,
  "/project/:page(create)": CreateProjectPage,
  "/project/feedback": FeedbackPage,
  "/project/analytics": Analytics,
  "/project/metrics/:metric?": MetricPage,
  "/project/summary/:summary?": SummaryPage,
  "/project/dialogs": DialogPage,
  "/project/dialogs/:bot": Messenger,
  "/project/ideas": IdeasPage,
  "/project/topicmodelling": TMPage,
  "/project/ideas/:idea": IdeaPage,
  "/project/feedback/:feedback": FeedbackIdea,
  "/project/tools": ToolsPage,
  "/project/:page(tools)/:action(create|edit)/:tool_id?": CreateToolPage,
  "/project/tools/:tool_id": ToolPage,
  "/billing": SubscriptionPage,*/
  // "/help": FAQPage
};
