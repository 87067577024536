import React, { useState, useRef } from "react";
import styled from "styled-components";
import Paper from "../../layout/paper";
import { Link } from "react-router-dom";
import { Typography, Form, Input, Button, Space, Spin } from "antd";
import { colors } from "../../layout/constants/colors";
import {
  CheckCircleFilled,
  LoadingOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { useEffect } from "react";

import emailjs from "@emailjs/browser";

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

const StyledForm = styled(Form)`
  &&& {
    .ant-form-item {
      flex-flow: column;
    }

    .ant-form-item-label > label {
      height: auto;
      color: #001520;
      max-width: 70%;
      line-height: 1.25;

      &::before {
        display: none;
      }
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${colors.lightgrey};
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%:
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  margin-bottom: 24px;

  &&& > * + * {
    margin-top: 24px;
  }

  &&& button {
    max-width: max-content;
    font-size: 16px;
  }

  &&& label {
    height: auto;
    color: #001520;
    max-width: 70%;
    line-height: 1.25;
    font-size: 14px;
  }
`;

const Feedback = ({ cityname = "", email = "" }) => {
  const [isSending, setSending] = useState(false);
  const [isSent, setSent] = useState(false);
  const [isError, setError] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_4t7ealb",
        "template_3dg9uqp",
        form.current,
        "eDer4k_VLnV0b0acp"
      )
      .then(
        (result) => {
          setSending(false);
          setSent(true);
        },
        (error) => {
          setSending(false);
          setError(true);
        }
      );
  };

  const handleSending = (e) => {
    setSending(true);
    sendEmail(e);
  };

  return (
    <Paper>
      <Wrapper>
        {(isSending || isSent || isError) && (
          <Overlay>
            {isSending && (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            )}

            {isSent && (
              <Space direction="vertical">
                <span style={{ color: "#41B256", fontSize: "2.8rem" }}>
                  <CheckCircleFilled />
                </span>
                <Paragraph level="-2">
                  Обращение принято. Письмо с ответом будет отправлено на
                  указанный при регистрации адрес электронной почты
                </Paragraph>
              </Space>
            )}

            {isError && (
              <Space direction="vertical">
                <span style={{ color: "#FD2424", fontSize: "2.8rem" }}>
                  <CloseCircleFilled />
                </span>
                <Paragraph level="-2">
                  Возникла ошибка при соединении с сервером. Попробуйте
                  отправить сообщение{" "}
                  <u
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setError(false);
                    }}
                  >
                    еще раз
                  </u>
                </Paragraph>
              </Space>
            )}
          </Overlay>
        )}
        <Title level={4}>Есть вопросы? Напишите нам</Title>

        {
          <FormWrapper ref={form} onSubmit={handleSending}>
            <FormItem>
              <label className="ant-form-item-required">Тема обращения</label>
              <input
                type="text"
                name="topic"
                className="ant-input ant-input-lg"
              />
            </FormItem>

            <FormItem>
              <label className="ant-form-item-required">
                Введите ваш вопрос о работе с Программой или личным кабинетом
              </label>
              <textarea
                rows="4"
                name="message"
                className="ant-input ant-input-lg"
              />
            </FormItem>

            <input type="hidden" name="cityname" value={cityname} />
            <input type="hidden" name="email" value={email} />

            <Button type="primary" htmlType="submit">
              Отправить
            </Button>
          </FormWrapper>
        }

        {null && (
          <StyledForm layout="vertical" size="large" onFinish={handleSending}>
            <form onSubmit={sendEmail}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Заполните пожалуйста поле!",
                  },
                ]}
                label="Тема обращения"
                name="topic"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Введите ваш вопрос о работе с Программой или личным кабинетом"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Заполните пожалуйста поле!",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>

              <Form.Item label="">
                <Button type="primary" htmlType="submit">
                  Отправить
                </Button>
              </Form.Item>

              <input type="submit" value="Send" />
            </form>
          </StyledForm>
        )}

        <Text level="1">
          Вы также можете отправить свой вопрос на электронную почту{" "}
          <Link
            to="#"
            onClick={(e) => {
              window.location.href = "mailto:veb@5shagov.ru";
              e.preventDefault();
            }}
          >
            <a style={{ color: colors.accent }}>support@5stepsforcities.ru</a>
          </Link>
        </Text>
      </Wrapper>
    </Paper>
  );
};

export default Feedback;
