import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Tokenizer from "../../../../helpers/tokenizer";

import { POST_PERFORMERS, GET_PERFORMERS } from "../../../../queries/5steps";
import { successFinish, failedFinish, handlePerformersError } from "../request";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import BackIcon from "../request/icons/back";
import { Gap } from "../layout";

import { StyledForm, TitleWrapper, BackBtn } from "../request";

import Layout from "../layout";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Form,
  Input,
  Grid,
  Skeleton,
  Row,
  Col,
} from "antd";

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const ExecutorsPage = () => {
  const [goToMain, setGoToMain] = useState(false);
  const [savedExecutors, setSavedExecutors] = useState(false);

  const [finishResponse, setFinishResponse] = useState(false);

  /* ***** */
  const [fullname1, setFullname1] = useState(null);
  const [email1, setEmail1] = useState(null);
  const [position1, setPosition1] = useState(null);

  const [fullname2, setFullname2] = useState(null);
  const [email2, setEmail2] = useState(null);
  const [position2, setPosition2] = useState(null);

  const [fullname3, setFullname3] = useState(null);
  const [email3, setEmail3] = useState(null);
  const [position3, setPosition3] = useState(null);

  const [initValues, setInitValues] = useState(null);

  const [readyCheckPerformers, setCheckPerformers] = useState(false);
  /* ***** */

  const screens = useBreakpoint();

  const { auth } = useSelector((state) => state);
  const bearer = Tokenizer.authHeader(auth.access_token);

  const mtnConfigs = {
    context: {
      headers: {
        authorization: bearer,
      },
    },
  };

  useEffect(() => {
    if (goToMain) {
      const timer = setTimeout(() => (location.href = "/"), 1400);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [goToMain]);

  const [postPerformers] = useMutation(POST_PERFORMERS, {
    ...mtnConfigs,
    onCompleted: (e) => {
      setSavedExecutors();
      successFinish(e);
      setSavedExecutors(true);
      checkPerformers();
      setGoToMain(true);
    },
    onError: failedFinish,
  });

  /* Запросы на получение Performers data */
  const { data: performers, loading: load_performers } = useQuery(
    GET_PERFORMERS,
    { ...mtnConfigs }
  );
  const [
    checkPerformers,
    { data: check_performers, loading: load_check_performers },
  ] = useLazyQuery(GET_PERFORMERS, {
    ...mtnConfigs,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (finishResponse && readyCheckPerformers) {
      const handleFirstError = () => {
        if (!savedExecutors) {
          location.href = "#sec-form";
          handlePerformersError();
          return;
        }
      };

      const firstError = handleFirstError();

      if (savedExecutors) {
        handleSecSuccess();
        setGoToMain(true);
      }

      setCheckPerformers(false);
      setFinishResponse(false);
    }
  }, [finishResponse, savedExecutors, readyCheckPerformers]);

  /* States */
  const handlePerformersStates = (performers, finishCheck = false) => {
    if (performers) {
      const { app5s_userPerformers = [] } = performers ? performers : {};
      const [performer1 = {}, performer2 = {}, performer3 = {}] =
        app5s_userPerformers;

      const {
        name: fullname1,
        email: email1,
        position: position1,
      } = performer1;
      const {
        name: fullname2,
        email: email2,
        position: position2,
      } = performer2;
      const {
        name: fullname3,
        email: email3,
        position: position3,
      } = performer3;

      const checkCondition =
        fullname1 &&
        email1 &&
        fullname2 &&
        email2 &&
        fullname3 &&
        email3 &&
        position3;

      if (!finishCheck) {
        setFullname1(fullname1);
        setEmail1(email1);
        setPosition1(position1);

        setFullname2(fullname2);
        setEmail2(email2);
        setPosition2(position2);

        setFullname3(fullname3);
        setEmail3(email3);
        setPosition3(position3);
      }

      if (checkCondition) {
        setSavedExecutors(true);
      }

      if (finishCheck) {
        setCheckPerformers(true);
        setFinishResponse(true);
      }

      if (!finishCheck) {
        setInitValues(true);
      }
    }
  };

  useEffect(() => {
    if (load_check_performers) {
      handlePerformersStates(check_performers, true);
    }
  }, [check_performers, load_check_performers]);

  useEffect(() => {
    handlePerformersStates(performers, false);
  }, [performers]);

  const handlePerformers = (e = {}) => {
    const {
      fullname1,
      fullname2,
      fullname3,
      email1,
      email2,
      email3,
      position3,
    } = e;

    const objects = [
      { name: fullname1, email: email1, position: null, ordering: 1 },
      { name: fullname2, email: email2, position: null, ordering: 2 },
      { name: fullname3, email: email3, position: position3, ordering: 3 },
    ];

    postPerformers({ variables: { objects } });
  };

  if (!load_performers && performers && initValues) {
    return (
      <Layout>
        <Link to="/">
          <a>
            <BackBtn>
              <span>
                <BackIcon />
              </span>

              <Text level="1">Вернуться в личный кабинет</Text>
            </BackBtn>
          </a>
        </Link>

        <Gap height="36px" />

        <TitleWrapper>
          <Title level={screens.lg ? 1 : 2}>
            Информация об&nbsp;ответственных лицах
          </Title>
        </TitleWrapper>

        <Gap height="1.8rem" />

        <StyledForm
          layout="vertical"
          size="large"
          onFinish={handlePerformers}
          scrollToFirstError
          id={`sec-performers`}
        >
          <Title level={5} data-size="larger">
            Глава муниципального образования*
          </Title>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Заполните пожалуйста поле!",
              },
            ]}
            label="ФИO"
            name="fullname1"
            initialValue={fullname1}
          >
            <Input />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Заполните пожалуйста поле!",
              },
              {
                type: "email",
                message: "Введите пожалуйста корректный email",
              },
            ]}
            label="Email"
            name="email1"
            initialValue={email1}
          >
            <Input />
          </Form.Item>

          <Gap height="2.1rem" />

          <Title level={5} data-size="larger">
            Заместитель главы муниципального образования*
          </Title>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Заполните пожалуйста поле!",
              },
            ]}
            label="ФИO"
            name="fullname2"
            initialValue={fullname2}
          >
            <Input />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Заполните пожалуйста поле!",
              },
              {
                type: "email",
                message: "Введите пожалуйста корректный email",
              },
            ]}
            label="Email"
            name="email2"
            initialValue={email2}
          >
            <Input />
          </Form.Item>

          <Gap height="2.1rem" />

          <Title level={5} data-size="larger">
            Другое ответственное лицо
          </Title>

          <Form.Item label="ФИO" name="fullname3" initialValue={fullname3}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Должность"
            name="position3"
            initialValue={position3}
          >
            <Input />
          </Form.Item>

          <Form.Item
            rules={[
              {
                type: "email",
                message: "Введите пожалуйста корректный email",
              },
            ]}
            label="Email"
            name="email3"
            initialValue={email3}
          >
            <Input />
          </Form.Item>

          <Text>* — обязательно к заполнению</Text>

          <Gap height="2rem" />
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Сохранить
            </Button>
          </Form.Item>
        </StyledForm>

        <Gap height="3.38rem" />
      </Layout>
    );
  }

  return (
    <Layout>
      <Row>
        <Col span={14}>
          <Skeleton paragraph={{ rows: 10 }} />
        </Col>
      </Row>
    </Layout>
  );
};

export default ExecutorsPage;
