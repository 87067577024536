import React, { useEffect } from "react";
import styled from "styled-components";
import { Modal, Button } from "antd";
import {
  Typography,
  Input,
  Form,
  Space,
  Menu,
  Dropdown,
  notification,
  Upload,
} from "antd";
import { StyledForm } from "..";
import { Gap } from "../../layout";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import axios from "axios";

import Tokenizer from "../../../../../helpers/tokenizer";
import {
  POST_OWN_PROJECTS,
  UPDATE_OWN_PROJECT,
} from "../../../../../queries/5steps";
import { SmileOutlined, DownOutlined } from "@ant-design/icons";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Thumb from "./thumb";
import { UploadBtn } from "./thumbPreview";

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

const Label = ({ name = "", more = null }) => {
  return (
    <Space direction="vertical" size={2}>
      <Title level={5} data-margin="nomargin" data-size="smaller">
        {name}
      </Title>
      {more && <Text level="1">{more}</Text>}
    </Space>
  );
};

const ModalWrapper = styled(Modal)`
  &&& {
    width: 100% !important;
    max-width: 840px;

    & .ant-modal-body {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
      max-width: calc(590px + 80px);
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const DropdownWrapper = styled.div`
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  height: calc(8.5px * 2 + 26px);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8.5px 11px;
`;

const StepsData = [
  {
    name: "Шаг 1: Город рядом",
  },
  {
    name: "Шаг 2: Сохрани планету",
  },
  {
    name: "Шаг 3: Вдохни жизнь",
  },
  {
    name: "Шаг 4: Добавь энергии",
  },
  {
    name: "Шаг 5: Собери своих",
  },
];

const StepsList = (activeStep, setActiveStep) => (
  <Space direction="vertical">
    {StepsData.map(({ name }, i) => (
      <a
        key={`step:${i}`}
        onClick={(e) => {
          e.preventDefault();
          setActiveStep(i + 1);
        }}
      >
        {name}
      </a>
    ))}
  </Space>
);

const successFinish = () => {
  notification.success({
    message: `Данные успешно сохранены`,
    placement: "top",
  });
};

const failedFinish = () => {
  notification.error({
    message: `Произошла ошибка при сохранении`,
    placement: "top",
  });
};

const AddProjectForm = ({
  active,
  close = () => {},
  bearer,
  setProjects = () => {},
  /* */
  editData = {},
}) => {
  const [activeStep, setActiveStep] = useState(1);

  const [localId, setLocalId] = useState(null);

  /* */
  const [thumbData, setThumbData] = useState(null);

  const [defaultFileList, setDefaultFileList] = useState([]);
  const [progress, setProgress] = useState(0);
  /* */

  useEffect(() => {
    if (editData) {
      const { step, file1, file, id } = editData;

      if (typeof step === "number") {
        setActiveStep(step);
      }
      setLocalId(id);
      setThumbData(file);

      if (file1) {
        setDefaultFileList(file1);
      }
    } else {
      setLocalId(uuidv4());
    }
  }, [editData]);

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();

    const imageNewName = uuidv4();
    const forDotArr = file.name.split(".");

    const imageType = forDotArr[forDotArr.length - 1];
    const name = `${imageNewName}.${imageType}`;

    const newFile = new File([file], name);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: bearer,
      },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    fmData.append("file", newFile);

    try {
      const res = await axios.post(
        "https://api.chg.one/v1/file/crowdsourcing/upload",
        fmData,
        config
      );

      const { data = [] } = res ? res : {};
      const firstItem = data.length > 0 ? data[0] : {};
      const { url } = firstItem;

      onSuccess(url);
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const uploadCfgs = {
    name: "file",
    customRequest: uploadImage,
    onChange(info) {
      /*if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }*/
      if (info.file.status === "done") {
        setDefaultFileList((state = []) => [
          ...state,
          ...info.fileList
            .filter(({ response }) => response)
            .map(({ response }) => response),
        ]);

        notification.success({
          message: `${info.file.name} успешно загружен`,
        });
      } else if (info.file.status === "error") {
        notification.error({
          message: `Ошибка при загрузке. Попробуйте позже`,
        });
      }
    },
  };

  /* ** */
  const { auth } = useSelector((state) => state);

  const mtnConfigs = {
    context: {
      headers: {
        authorization: Tokenizer.authHeader(auth.access_token),
      },
    },
  };

  const [postOwnProjects, { error: error_own_prohect }] = useMutation(
    POST_OWN_PROJECTS,
    {
      ...mtnConfigs,
      onCompleted: (e = {}) => {
        const { app5s_createProjectMeta = {}, app5s_createProject = {} } = e;
        const { title: meta_title = "", project_id } = app5s_createProjectMeta;
        const { owner_user_id } = app5s_createProject;

        close();
        setProjects((state) => [
          ...state,
          {
            id: project_id,
            owner_user_id,
            meta: {
              title: meta_title,
            },
          },
        ]);

        return successFinish();
      },
      onError: failedFinish,
    }
  );

  const [updateOwnProject, { error: error_updated_own_project }] = useMutation(
    UPDATE_OWN_PROJECT,
    {
      ...mtnConfigs,
      onCompleted: (e = {}) => {
        const { app5s_updateProject = {} } = e;
        const { meta = {}, id: req_id } = app5s_updateProject;
        const { title } = meta;

        setProjects((state = []) => {
          return state.map((item = {}) => {
            const { id } = item;

            if (id === req_id) {
              let _item = { ...item };
              _item.meta = { title };

              return _item;
            }

            return item;
          });
        });

        close();

        return successFinish();
      },
      onError: failedFinish,
    }
  );

  if (error_updated_own_project)
    console.log("error_updated_own_project", error_updated_own_project);

  const handleFinish = (e) => {
    if (!editData) {
      const { step, ...otherMetaData } = { ...e };
      const v4_id = uuidv4();
      const meta = { id: v4_id, step: activeStep, ...otherMetaData };
      const {
        id,
        title,
        about,
        about_actual,
        about_crowdsourcing,
        about_finance,
        step: _step,
      } = meta;

      postOwnProjects({
        variables: {
          id,
          title,
          about,
          about_actual,
          about_crowdsourcing,
          about_finance,
          file: thumbData,
          file1: defaultFileList,
          step: _step,
        },
      });

      return meta;
    } else {
      const { step, ...otherMetaData } = { ...e };
      const meta = { id: editData.id, step: activeStep, ...otherMetaData };

      const {
        id,
        title,
        about,
        about_actual,
        about_crowdsourcing,
        about_finance,
        step: _step,
      } = meta;

      console.log("_step", _step);
      console.log(typeof _step);

      updateOwnProject({
        variables: {
          id,
          title,
          about,
          about_actual,
          about_crowdsourcing,
          about_finance,
          file: thumbData,
          file1: defaultFileList,
          step: _step,
        },
      });
    }
  };

  return (
    <ModalWrapper
      key={localId}
      visible={active}
      footer={null}
      maskStyle={{ background: "#131C27", opacity: 0.9 }}
      onCancel={() => close(false)}
    >
      <Title level={2}>
        {!editData ? `Добавить свой проект` : "Изменить свой проект"}
      </Title>
      {!editData && (
        <Paragraph level="1">
          Для того, чтобы предложить собственный проект, не входящий в палитру
          Программы, введите необходимые сведения о нем и нажмите «Отправить
          запрос на рассмотрение». Предложенный проект будет добавлен к
          остальным проектам при условии его соответствия цели и задачам
          Программы. При необходимости он может заменить один из ранее выбранных
          проектов Палитры. В процессе рассмотрения запроса команда Программы
          свяжется с вами для уточнения деталей
        </Paragraph>
      )}

      <Gap height={!editData ? "4rem" : "2rem"} />

      <StyledForm
        layout="vertical"
        size="large"
        $goal="addproject"
        onFinish={handleFinish}
        {...(editData
          ? {
              initialValues: {
                title: editData.title,
                about: editData.about,
                about_finance: editData.about_finance,
                about_crowdsourcing: editData.about_crowdsourcing,
                about_actual: editData.about_actual,
              },
            }
          : {})}
      >
        <Form.Item
          label={<Label name="Название проекта" />}
          name="title"
          rules={[
            {
              required: true,
              message: "Заполните пожалуйста поле!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<Label name="Соответствие шагу программы" />}
          name="step"
        >
          <Dropdown overlay={() => StepsList(activeStep, setActiveStep)}>
            <DropdownWrapper>
              <a onClick={(e) => e.preventDefault()}>
                <Space>{StepsData[activeStep - 1].name}</Space>
              </a>
              <DownOutlined />
            </DropdownWrapper>
          </Dropdown>
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Заполните пожалуйста поле!",
            },
          ]}
          label={
            <Label
              name="Описание проекта"
              more="Кратко опишите цель проекта, его основную идею, целевую аудиторию, принципиальные подходы к его реализации"
            />
          }
          name="about"
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Заполните пожалуйста поле!",
            },
          ]}
          label={
            <Label
              name="Обоснование актуальности проекта"
              more="Кратко опишите, почему именно этот проект важно реализовать, оцените его соответствие запросам целевой аудитории, приведите потенциальные эффекты от его реализации"
            />
          }
          name="about_actual"
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Заполните пожалуйста поле!",
            },
          ]}
          label={
            <Label
              name="Потенциальные источники финансирования проекта"
              more="Укажите возможные источники финансирования проекта"
            />
          }
          name="about_finance"
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Заполните пожалуйста поле!",
            },
          ]}
          label={
            <Label
              name="Подходы к вовлечению горожан в реализацию проекта"
              more="Кратко опишите, каким образом планируется вовлекать горожан в реализацию проекта"
            />
          }
          name="about_crowdsourcing"
        >
          <TextArea rows={4} />
        </Form.Item>

        {/*<Form.Item
          label={
            <Label
              name="Дополнительные материалы"
              more="При необходимости прикрепите дополнительные материалы о проекте, например краткую презентацию или подборку примеров одним файлом в форматах .pdf, .doc, .ppt"
            />
          }
          name="file"
        >
          <Button type="secondary">Прикрепить файл</Button>
        </Form.Item>*/}
        <Form.Item
          label={
            <Label
              name="Дополнительные материалы"
              more="При необходимости прикрепите дополнительные материалы о проекте, например краткую презентацию или подборку примеров одним файлом в форматах .pdf, .doc, .ppt"
            />
          }
        >
          <Gap height="1.5rem" />

          <Upload
            {...uploadCfgs}
            {...(editData
              ? {
                  defaultFileList: editData.file1.map((url, i) => {
                    return {
                      uid: i,
                      name: `Файл ${i + 1}`,
                      status: "done",
                      url,
                    };
                  }),
                }
              : {})}
          >
            <UploadBtn>
              <span>Выбрать файл</span>
            </UploadBtn>
          </Upload>
        </Form.Item>

        <Gap height="3.5rem" />

        <Button type="primary" htmlType="submit" data-size="big">
          {!editData ? `Предложить проект` : `Обновить проект`}
        </Button>
      </StyledForm>
    </ModalWrapper>
  );
};

export default AddProjectForm;
