import React, { useState } from "react";
import styled from "styled-components";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import Tokenizer from "../../../../helpers/tokenizer";
import {
  GET_STEPS,
  POST_PROJECTS,
  GET_SELECTED_OBJECTS,
  CHECK_SELECTED_OBJECTS,
  GET_CITY_META,
  DELETE_OWN_PROJECTS,
} from "../../../../queries/5steps";

import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Form,
  Space,
  Grid,
  Skeleton,
  Row,
  Col,
  Popconfirm,
  notification,
} from "antd";
import { colors } from "../layout/constants/colors";
import Section from "./section";

import AddProjectForm from "./project";
import Layout from "../layout";
import BackIcon from "./icons/back";
import { Gap } from "../layout";
import { useEffect } from "react";

import { v4 as uuidv4 } from "uuid";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const { Title, Paragraph, Text } = Typography;
const { useBreakpoint } = Grid;

export const StyledForm = styled(Form)`
  &&& {
    h1 {
      font-size: 1.25;
    }

    .ant-form-item {
      width: 100%;
      max-width: 490px;
      margin-bottom: 1rem;

      ${({ $goal }) =>
        $goal === "addproject"
          ? `
        & {
          margin-bottom: 2.1rem;
        }
        `
          : ``}
    }

    .ant-form-item-label > label {
      height: auto;
      color: ${colors.grey};

      &::before {
        display: none;
      }
    }

    span {
      color: ${colors.grey};
    }

    button.ant-btn-primary span {
      color: ${colors.white};
    }
  }
`;

export const TitleWrapper = styled.div`
  @media (min-width: 992px) {
    max-width: 60%;
  }
`;

export const BackBtn = styled.div`
  &&&&& * {
    color: ${colors.accent};

    &:hover {
      filter: brightness(1.2);
    }
  }

  display: flex;
  align-items: center;

  && > * + * {
    margin-left: 10px;
  }

  && span:nth-child(1) {
    font-size: 2.25rem;
  }
`;

export const ProjectsLinks = styled(Space)`
  &&&&&& {
    &,
    & * {
      color: ${colors.accent};
    }
  }
`;

const handleSecError = () => {
  notification.error({
    message: "Выбрано недостаточно проектов для данного шага",
  });
};

export const handlePerformersError = () => {
  notification.error({
    message: "Анкета не до конца заполнена",
  });
};

const handleSecSuccess = () => {
  notification.success({
    message: "Спасибо! Все данные успешно сохранены. Возвращаемся на главную",
  });
};

export const successFinish = () => {
  notification.success({
    message: `Данные успешно сохранены`,
    placement: "top",
  });
};

export const failedFinish = () => {
  notification.error({
    message: `Произошла ошибка при сохранении`,
    placement: "top",
  });
};

const handleSelectedProjectsData = (response = {}, setData = () => {}) => {
  if (response) {
    const { app5s_userProjectsSelection = [], app5s_projects = [] } = response;

    let formattedProjects = app5s_userProjectsSelection
      .filter(({ project }) => {
        if (project && project.step) return true;
      })
      .map(({ project_id, project = {} }) => {
        const { step, is_priority, owner_user_id, is_approved } = project;
        return { project_id, step, is_priority, owner_user_id };
      })
      .filter(({ owner_user_id }) => !owner_user_id);

    let ownFormattedProjects = app5s_projects.map((item = {}) => {
      const { id, step, is_priority, owner_user_id, is_approved } = item;

      return {
        project_id: id,
        step,
        is_priority,
        owner_user_id,
        is_approved,
      };
    });

    setData([...formattedProjects, ...ownFormattedProjects]);
  }
};

const RequestPage = () => {
  const [addProjectForm, setAddProjectForm] = useState(false);
  const [projectFormId, setProjectFormId] = useState(uuidv4());

  const [editData, setEditData] = useState(null);

  const [selectedProjects, setSelectedProjects] = useState([]);

  const [finishResponse, setFinishResponse] = useState(false);
  const [requiresPost, setRequiresPost] = useState(false);

  const [savedSec1, setSavedSec1] = useState(false);
  const [savedSec2, setSavedSec2] = useState(false);
  const [savedSec3, setSavedSec3] = useState(false);
  const [savedSec4, setSavedSec4] = useState(false);
  const [savedSec5, setSavedSec5] = useState(false);

  const [goToMain, setGoToMain] = useState(false);

  const [readyCheckSection, setReadyCheckSection] = useState(false);

  const [stepSections, setStepSections] = useState([]);
  const [projects, setProjects] = useState([]);

  const screens = useBreakpoint();

  const { auth } = useSelector((state) => state);

  const bearer = Tokenizer.authHeader(auth.access_token);

  const mtnConfigs = {
    context: {
      headers: {
        authorization: bearer,
      },
    },
  };

  /* user data */
  const {
    data: cityMeta,
    loading: loading_cityMeta,
    error: err_cityMeta,
  } = useQuery(GET_CITY_META, {
    ...mtnConfigs,
  });

  const { me = [] } = cityMeta ? cityMeta : {};
  const { meta = {} } = me.length > 0 ? me[0] : {};
  const { occupation } = meta;

  /* Steps and projects data */
  const { data: steps, loading: load_steps } = useQuery(GET_STEPS, {
    ...mtnConfigs,
  });

  useEffect(() => {
    if (steps) {
      const { app5s_steps = [], app5s_projects = [] } = steps;

      setStepSections(app5s_steps);
      setProjects(app5s_projects);
    }
  }, [steps]);
  /* */

  useEffect(() => {
    if (goToMain) {
      const timer = setTimeout(() => (location.href = "/"), 1400);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [goToMain]);

  const [postProjects, { data, error: post_error }] = useMutation(
    POST_PROJECTS,
    {
      ...mtnConfigs,
      onCompleted: (e) => {
        handleSecSuccess();
        setGoToMain(true);
      },
      onError: failedFinish,
    }
  );

  if (post_error) console.log("POST_PROJECTS", post_error);

  /* Запросы на получение выбранных проектов */
  const { data: userSelProjects, loading: s_loading } = useQuery(
    GET_SELECTED_OBJECTS,
    {
      ...mtnConfigs,
    }
  );

  const [
    checkSelProjects,
    { data: check_userSelProjects, loading: load_check_userSelProjects },
  ] = useLazyQuery(CHECK_SELECTED_OBJECTS, {
    ...mtnConfigs,
    fetchPolicy: "network-only",
  });

  const [
    deleteProject,
    { data: deletedData, loading: deletedLoading, error: deletedError },
  ] = useMutation(DELETE_OWN_PROJECTS, {
    ...mtnConfigs,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (deletedData) {
      notification.success({
        message: `Проект успешно удален`,
      });

      const timer = setTimeout(() => {
        window.location.reload();
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
    if (deletedError) {
      notification.error({
        message: `Произошла ошибка при удалении проекта`,
      });
    }
  }, [deletedData, deletedError]);

  useEffect(() => {});

  const finishRequest = () => {
    handleCheckProjects(1);
    handleCheckProjects(2);
    handleCheckProjects(3);
    handleCheckProjects(4);
    handleCheckProjects(5);

    setFinishResponse(true);
    setReadyCheckSection(true);
  };

  useEffect(() => {
    if (finishResponse && readyCheckSection) {
      const handleFirstError = () => {
        if (!savedSec1) {
          location.href = "#sec-wrapper1";
          handleSecError();
          return;
        }

        if (!savedSec2) {
          location.href = "#sec-wrapper2";
          handleSecError();
          return;
        }

        if (!savedSec3) {
          location.href = "#sec-wrapper3";
          handleSecError();
          return;
        }

        if (!savedSec4) {
          location.href = "#sec-wrapper4";
          handleSecError();
          return;
        }

        if (!savedSec5) {
          location.href = "#sec-wrapper5";
          handleSecError();
          return;
        }
      };

      const firstError = handleFirstError();

      if (savedSec1 && savedSec2 && savedSec3 && savedSec4 && savedSec5) {
        handleProjects();
      }

      setReadyCheckSection(false);
      setFinishResponse(false);
    }
  }, [
    finishResponse,
    savedSec1,
    savedSec2,
    savedSec3,
    savedSec4,
    savedSec5,
    readyCheckSection,
  ]);

  const handleProjectsStates = (response) => {
    if (response) {
      const { app5s_userProjectsSelection = [] } = response;

      let formattedProjects = app5s_userProjectsSelection;

      let steps = {};
      formattedProjects.map((item = {}) => {
        const { project = {} } = item;
        const { step, key, is_priority } = project;

        if (!steps[`step${step}`]) {
          steps[`step${step}`] = { priority: 0, all: 0 };
        }

        if (is_priority) {
          steps[`step${step}`].priority += 1;
        }

        steps[`step${step}`].all += 1;
      });

      const minPrior =
        occupation === "A" || occupation === "B" || occupation === "C" ? 2 : 0;
      const minAll =
        occupation === "A" || occupation === "B" || occupation === "C" ? 3 : 1;

      /* проверка количества выбранных приоритетных и доп проектов */
      if (
        steps[`step1`] &&
        steps[`step1`].priority >= minPrior &&
        steps[`step1`].all >= minAll
      )
        setSavedSec1(true);
      if (
        steps[`step2`] &&
        steps[`step2`].priority >= minPrior &&
        steps[`step2`].all >= minAll
      )
        setSavedSec2(true);
      if (
        steps[`step3`] &&
        steps[`step3`].priority >= minPrior &&
        steps[`step3`].all >= minAll
      ) {
        setSavedSec3(true);
      }
      if (
        steps[`step4`] &&
        steps[`step4`].priority >= minPrior &&
        steps[`step4`].all >= minAll
      )
        setSavedSec4(true);
      if (
        steps[`step5`] &&
        steps[`step5`].priority >= minPrior &&
        steps[`step5`].all >= minAll
      )
        setSavedSec5(true);

      setReadyCheckSection(true);
      setFinishResponse(true);
    }
  };

  useEffect(() => {
    if (check_userSelProjects) {
      handleProjectsStates(check_userSelProjects);
    }
  }, [check_userSelProjects]);

  /* загружаем все проекты, выбранные пользователем */
  useEffect(() => {
    handleSelectedProjectsData(userSelProjects, setSelectedProjects);
  }, [userSelProjects]);

  const handleCheckProjects = (step_index) => {
    const minPrior =
      occupation === "A" || occupation === "B" || occupation === "C" ? 2 : 0;
    const minAll =
      occupation === "A" || occupation === "B" || occupation === "C" ? 3 : 1;

    let stepsRules = {
      1: {
        all: minAll,
        priority: minPrior,
      },
      2: {
        all: minAll,
        priority: minPrior,
      },
      3: {
        all: minAll,
        priority: minPrior,
      },
      4: {
        all: minAll,
        priority: minPrior,
      },
      5: {
        all: minAll,
        priority: minPrior,
      },
    };

    const currStep_projects = selectedProjects.filter(
      ({ step }) => step === step_index
    );

    let all = 0;
    let priority = 0;

    currStep_projects.map(({ is_priority: prior }) => {
      if (prior) priority += 1;
      all += 1;
    });

    if (
      !(
        all >= stepsRules[step_index].all &&
        priority >= stepsRules[step_index].priority
      )
    ) {
      if (step_index === 1) return setSavedSec1(false);
      if (step_index === 2) return setSavedSec2(false);
      if (step_index === 3) return setSavedSec3(false);
      if (step_index === 4) return setSavedSec4(false);
      if (step_index === 5) return setSavedSec5(false);
    }

    if (step_index === 1) return setSavedSec1(true);
    if (step_index === 2) return setSavedSec2(true);
    if (step_index === 3) return setSavedSec3(true);
    if (step_index === 4) return setSavedSec4(true);
    if (step_index === 5) return setSavedSec5(true);
  };

  const handleProjects = () => {
    const currStep_projects = selectedProjects;

    const filtered_projects = currStep_projects.map(({ project_id }) => ({
      project_id,
    }));

    postProjects({
      variables: { objects: filtered_projects },
    });
  };

  if (userSelProjects && !load_steps && !s_loading)
    return (
      <Layout>
        <AddProjectForm
          key={projectFormId}
          bearer={bearer}
          active={addProjectForm}
          {...{ editData, setEditData }}
          close={(e) => {
            setEditData(null);
            setAddProjectForm(e);
          }}
          setProjects={setProjects}
        />

        <Link to="/">
          <a>
            <BackBtn>
              <span>
                <BackIcon />
              </span>

              <Text level="1">Вернуться в личный кабинет</Text>
            </BackBtn>
          </a>
        </Link>

        <Gap height="36px" />

        <Title level={screens.lg ? 1 : 2}>Выбор проекта</Title>

        {!load_steps &&
          steps &&
          stepSections.map((props = {}) => {
            const { step: step_index, title } = props;
            const section_projects = projects
              .filter(({ step }) => step === step_index)
              .sort(({ is_priority }) => is_priority);

            return (
              <>
                <Section
                  {...{
                    step_index,
                    section_projects,
                    title,
                    selectedProjects,
                    setSelectedProjects,
                    finish: handleProjects,
                    occupation,
                  }}
                />
                <Gap height={step_index === 5 ? "4.25rem" : "4.62rem"} />
              </>
            );
          })}

        <Space direction="vertical" style={{ maxWidth: "715px" }}>
          <Title level={3}>Предложить свой проект</Title>
          <Paragraph level="1">
            Для того, чтобы предложить собственный проект, не входящий в палитру
            Программы, введите необходимые сведения о нем и нажмите «Отправить
            запрос на рассмотрение». Предложенный проект будет добавлен к
            остальным проектам при условии его соответствия цели и задачам
            Программы. При необходимости он может заменить один из ранее
            выбранных проектов Палитры. В процессе рассмотрения запроса команда
            Программы свяжется с вами для уточнения деталей.
          </Paragraph>

          <ProjectsLinks direction="vertical" id="custom_projects">
            {projects
              .filter(({ owner_user_id }) => owner_user_id)
              .map((props = {}) => {
                console.log("props", props);

                const { meta = {}, step, id, is_approved } = props;
                const {
                  title,
                  about,
                  about_finance,
                  about_crowdsourcing,
                  about_actual,
                  file1,
                  file,
                } = meta;

                return (
                  <a key={`f:${id}`}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {typeof step === "number" && (
                        <Text
                          level="1"
                          style={{ paddingRight: "10px" }}
                        >{`Шаг ${step}:`}</Text>
                      )}

                      <Text
                        style={{ maxWidth: "300px" }}
                        level="1"
                        ellipsis={{ rows: 1 }}
                      >
                        {title}
                      </Text>
                      {is_approved && <Text level="1">{<i>(Одобрен)</i>}</Text>}

                      {/* Панель редактирования проекта */}
                      {
                        <Space
                          size={7}
                          style={{ marginLeft: "16px", opacity: 0.6 }}
                        >
                          {/* Изменить */}
                          <EditOutlined
                            onClick={() => {
                              setEditData({
                                id,
                                step,
                                title,
                                about,
                                about_finance,
                                about_crowdsourcing,
                                about_actual,
                                file1,
                                file,
                              });
                              setAddProjectForm(true);
                            }}
                          />

                          {/* Удалить */}
                          <Popconfirm
                            title="Вы действительно хотите удалить проект"
                            onConfirm={() =>
                              deleteProject({ variables: { id } })
                            }
                            onCancel={() => {}}
                            okText="Да"
                            cancelText="Нет"
                          >
                            <DeleteOutlined
                              style={{ transform: "scale(.8)" }}
                            />
                          </Popconfirm>
                        </Space>
                      }
                    </div>
                  </a>
                );
              })}
          </ProjectsLinks>

          <Gap height={"1rem"} />

          <Button
            type="secondary"
            onClick={() => {
              setProjectFormId(uuidv4());
              setAddProjectForm(true);
            }}
          >
            {projects.filter(({ owner_user_id }) => owner_user_id).length > 0
              ? "Добавить еще проект"
              : "Добавить свой проект"}
          </Button>
        </Space>

        <Gap height="4.25rem" />

        <Button type="primary" data-size="large" onClick={finishRequest}>
          Сохранить
        </Button>
      </Layout>
    );

  return (
    <Layout>
      <Row>
        <Col span={14}>
          <Skeleton paragraph={{ rows: 10 }} />
        </Col>
      </Row>
    </Layout>
  );
};

export default RequestPage;
