import React, { useState, useEffect } from "react";
import { Progress, notification, Space, Grid } from "antd";
import { UploadBtn } from "./thumbPreview";
import UploadService from "./upload-service";
import { ALink } from "../../homepage/step-3";


const { useBreakpoint } = Grid;

const handleFileSizeChecking = (file = {}) => {
  const { size = 0 } = file;
  if (size / 1000 / 1000 < 200) return true;

  return false;
};

const openNotification = (description) => {
  notification.error({
    message: `Ошибка файла`,
    description,
    placement: "bottomRight",
  });
};

const UploadFiles = ({ thumbData, setThumbData, bearer }) => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [uploadBtn, setUploadBtn] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [preName, setPreName] = useState(null);

  const screens = useBreakpoint();

  /* */
  const selectFile = (event) => {
    if (!handleFileSizeChecking(event.target.files?.[0]))
      return openNotification("файл не должен превышать 200 Мб");

    if (event.target.files) {
      setPreName(event.target.files?.[0].name);
      setUploadBtn(true);

      setSelectedFiles(event.target.files);
    }
  };
  /* */

  /* */
  useEffect(() => {
    if (selectedFiles) {
      let currentFile = selectedFiles[0];

      setProgress(0);
      setCurrentFile(currentFile);
      UploadService.upload(currentFile, bearer, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
        .then((response = {}) => {
          const { data = [] } = response;
          const { url = "", name = "" } =
            data && data.length > 0 ? data[0] : {};

          setThumbData(url);
        })
        .then(() => {
          /*возвращает undefined*/
        })
        .catch((error) => {
          console.log("error", error.message);
          /* выдаст ошибку */
        });
      setSelectedFiles(undefined);
    }
  }, [selectedFiles]);
  /* */

  const handleDelete = () => {
    setSelectedFiles(undefined);
    setCurrentFile(undefined);
    setProgress(0);
    setUploadBtn(false);
    setImageUrl(null);
    setPreName(null);
    setThumbData(null);
  };

  const handleName = (name = "") => {
    const l = name.length;
    const max = 20;

    if (l > 10) name = "..." + name.substring(l - max);

    return name;
  };

  return (
    <>
      {currentFile && progress === 100 && !imageUrl && (
        <Space
          size={screens.sm ? 30 : 10}
          direction={screens.sm ? "horizontal" : "vertical"}
        >
          <span>{handleName(preName)}</span>

          <ALink onClick={handleDelete}>Заменить</ALink>
        </Space>
      )}

      {currentFile && progress < 100 && !imageUrl && (
        <Progress type="circle" width={70} percent={progress} />
      )}

      {!currentFile && !imageUrl && (
        <>
          {!uploadBtn && (
            <label
              style={{ width: "100%", height: "100%" }}
              htmlFor="file-upload"
              className="custom-file-upload"
            >
              <UploadBtn>
                <span>Выбрать файл</span>
              </UploadBtn>
            </label>
          )}
          <input
            style={{ display: "none" }}
            id="file-upload"
            type="file"
            onChange={selectFile}
          />

          {/*uploadBtn && (
            <UploadBtn
              fill={colors.accent}
              disabled={!selectedFiles}
              onClick={upload}
            >
              {preName && (
                <span
                  style={{
                    fontWeight: 500,
                    zIndex: 2,
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  <i style={{ fontSize: "10px" }}>...{preName}</i>
                </span>
              )}
              <span style={{ zIndex: 2 }}>Загрузить</span>
            </UploadBtn>
                )*/}
        </>
      )}
    </>
  );
};

export default UploadFiles;
