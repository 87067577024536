import { gql } from "@apollo/client";

const STAGES = gql`
  query Stages {
    app5s_usersStages {
      stage01
      stage02
      stage03
    }
  }
`;

const GET_PERFORMERS = gql`
  query PerformersQuery {
    app5s_userPerformers(order_by: { ordering: asc }) {
      id
      name
      position
      email
    }
  }
`;

const POST_PERFORMERS = gql`
  mutation PerformerMutation(
    $objects: [app_5steps_user_performer_sat_insert_input!]!
  ) {
    app5s_deleteUserPerformers(where: { ordering: { _gte: 0 } }) {
      affected_rows
    }
    app5s_createUserPerformers(objects: $objects) {
      affected_rows
    }
  }
`;

const GET_STEPS = gql`
  query StepProjectsQuery {
    app5s_steps {
      step
      title
      userProjects(order_by: { ordering: asc }) {
        id
        is_approved
        is_priority
        meta {
          title
          thumb
        }
        is_selected
        is_owned
      }
    }

    app5s_projects {
      step
      ordering
      key
      is_approved
      owner_user_id
      meta {
        title
        about
        about_finance
        about_crowdsourcing
        about_actual
        file
        file1
      }
      is_priority
      id
      about
      targetA
      targetB
      targetC
      targetD
      thumb
      cr
    }
  }
`;

const CHECK_SELECTED_OBJECTS = gql`
  query getSelectedObjects {
    app5s_userProjectsSelection(
      where: { project: { owner_user_id: { _is_null: true } } }
    ) {
      project_id
      project {
        step
        key
        is_priority
        is_approved
      }
    }
  }
`;

const GET_SELECTED_OBJECTS = gql`
  query getSelectedObjects {
    app5s_userProjectsSelection {
      project_id
      project {
        step
        key
        is_priority
        is_approved
        owner_user_id
      }
    }

    app5s_projects(where: { owner_user_id: { _is_null: false } }) {
      is_approved
      is_priority
      owner_user_id
      step
      id
    }
  }
`;

const GET_SELECTED_OBJECTS_WITH_STEPS = gql`
  query getSelectedObjects {
    app5s_userProjectsSelection {
      project_id
      project {
        step
        key
        is_priority
        is_approved
        owner_user_id
        targetA
        targetB
        targetC
        targetD
      }
    }

    app5s_projects(where: { owner_user_id: { _is_null: false } }) {
      is_approved
      is_priority
      owner_user_id
      step
      id
      key
      meta {
        title
      }
      targetA
      targetB
      targetC
      targetD
    }
  }
`;

const POST_PROJECTS = gql`
  mutation SelectProjectsMutation2(
    $objects: [app_5steps_user_project_selection_insert_input!]!
    $step: Int
  ) {
    app5s_deleteProjectSeletions(
      where: { project: { step: { _is_null: false } } }
    ) {
      affected_rows
    }

    app5s_selectProjects(objects: $objects) {
      affected_rows
    }
  }
`;
/* app5s_selectProjects(objects: $objects) {
      affected_rows
    }*/

/* app5s_deleteProjectSeletions(where: { project: { step: { _eq: $step } } }) {
      affected_rows
    }*/

export const DELETE_OWN_PROJECTS = gql`
  mutation deleteUserProject($id: uuid!) {
    app5s_deleteProject(id: $id) {
      id
    }
    app5s_deleteProjectMeta(project_id: $id) {
      project_id
    }
  }
`;

const POST_OWN_PROJECTS = gql`
  mutation CreateProjectMutation(
    $id: uuid!
    $title: String!
    $about: String
    $about_actual: String
    $about_crowdsourcing: String
    $about_finance: String
    $file: String
    $file1: jsonb
    $step: Int!
  ) {
    app5s_createProject(
      object: { id: $id, step: $step, key: $title, about: $about }
    ) {
      id
      owner_user_id
    }
    app5s_createProjectMeta(
      object: {
        project_id: $id
        title: $title
        about: $about
        about_actual: $about_actual
        about_finance: $about_finance
        about_crowdsourcing: $about_crowdsourcing
        file: $file
        file1: $file1
      }
    ) {
      project_id
      title
    }
  }
`;

export const UPDATE_OWN_PROJECT = gql`
  mutation UpdateProjectMutation(
    $id: uuid!
    $title: String!
    $about: String
    $about_actual: String
    $about_crowdsourcing: String
    $about_finance: String
    $file: String
    $file1: jsonb
    $step: Int!
  ) {
    app5s_updateProjectMeta(
      pk_columns: { project_id: $id }
      _set: {
        title: $title
        file1: $file1
        file: $file
        about_finance: $about_finance
        about_crowdsourcing: $about_crowdsourcing
        about_actual: $about_actual
        about: $about
      }
    ) {
      project_id
    }

    app5s_updateProject(
      pk_columns: { id: $id }
      _set: { about: $about, key: $title, step: $step }
    ) {
      id
      meta {
        title
      }
    }
  }
`;

const GET_DOCS = gql`
  query DocsQuery {
    app5s_userDocs(order_by: { checkpoint: asc }) {
      doc_id
      checkpoint
      file
    }
  }
`;

const POST_DOCS = gql`
  mutation CreateDocsMutation($objects: [app_5steps_user_docs_insert_input!]!) {
    app5s_deleteUserDocs(where: { checkpoint: { _lte: 5 } }) {
      affected_rows
    }
    app5s_createUserDocs(objects: $objects) {
      affected_rows
    }
  }
`;

const GET_CITY_META = gql`
  query getCityMeta {
    me {
      meta {
        user_id
        avatar
        birthdate
        city
        country
        email
        first_name
        gender
        last_name
        occupation
        phone
        timezone
      }
    }
  }
`;

export const GET_CITY_PARTNERS = gql`
  query MyQuery($user_id: uuid!) {
    app_5steps_partners(
      where: {
        _or: [
          { partner: { user_id: { _eq: $user_id } } }
          { is_global: { _eq: true } }
        ]
      }
    ) {
      city_text
      comment
      contacts
      cr
      dateput
      fio
      id
      interest
      is_global
      mail
      num
      region_text
      title
    }
  }
`;

const GET_CITIES_LIST_AS_ANSWERS = gql`
  query getCitiesAsAnswers {
    dialog_bot(where: { id: { _eq: "a5de15c0-5f72-7001-5e0f-8871d4c6cdcc" } }) {
      id
      voc_link {
        voc {
          nodes {
            answers {
              name
            }
            key
            text
          }
        }
      }
    }
  }
`;

const GET_LOCAL_CITY_IDEAS = gql`
  query getCitizensIdeasForLocalCity($_contains: jsonb!) {
    chats(
      where: {
        id: {
          _nin: [
            "8c3ef3b6-571c-7f7d-5d80-ae64cb45e97e"
            "8246a6b0-67f9-61e9-043e-062dcca6dc26"
            "82f27f6f-54e8-6340-e631-a3e6a5bd12e2"
            "ea9975f3-a862-c735-f206-96f74febd04e"
            "4164240f-5560-17e6-526e-61d67e41d615"
            "01c1bd9a-0235-39aa-49df-41026c6ad40f"
            "1a29eed5-4da4-75fc-d43c-d075c74b8af5"
            "94e7516b-d77b-9365-be57-b35bdbb6e9db"
          ]
        }
        bot_id: { _eq: "a5de15c0-5f72-7001-5e0f-8871d4c6cdcc" }
        bot: {
          project_link: {
            project_id: { _eq: "e5f39eb8-f84f-42e9-afda-cde8271ddbd7" }
          }
        }
        dialogs: { message: { _contains: $_contains } }
      }
      order_by: { cr: desc_nulls_last }
    ) {
      id
      cr
      dialogs {
        id
        message
      }
    }
  }
`;

const GET_CITIES_WITHOUT_CHECKPOINT_2 = gql`
  query CitiesWithoutSecondCheckpoint {
    app5s_userDocs(
      where: { _and: { file: { _is_null: true }, checkpoint: { _eq: 2 } } }
    ) {
      user_id
    }
  }
`;

export {
  STAGES,
  GET_PERFORMERS,
  POST_PERFORMERS,
  GET_STEPS,
  POST_OWN_PROJECTS,
  POST_PROJECTS,
  CHECK_SELECTED_OBJECTS,
  GET_SELECTED_OBJECTS,
  GET_SELECTED_OBJECTS_WITH_STEPS,
  GET_DOCS,
  POST_DOCS,
  GET_CITY_META,
  /* */
  GET_CITIES_LIST_AS_ANSWERS,
  GET_LOCAL_CITY_IDEAS,
  GET_CITIES_WITHOUT_CHECKPOINT_2,
};
