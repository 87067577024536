import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "../layout";
import Paper from "../layout/paper";
import { DownloadOutlined } from "@ant-design/icons";
import extraFormattedIdeas from "./extra-formatted-ideas.json";
import warningGroups from "./warning-groups.json";
import warningCities from "./warning-cities.json";
import {
  Typography,
  Row,
  Col,
  Space,
  Grid,
  Skeleton,
  Result,
  Button,
  Tooltip,
  Modal,
  Pagination,
} from "antd";
import { Gap } from "../layout";

import { useSelector } from "react-redux";
import Tokenizer from "../../../../helpers/tokenizer";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  STAGES,
  GET_SELECTED_OBJECTS,
  GET_PERFORMERS,
  GET_CITY_META,
  GET_CITIES_LIST_AS_ANSWERS,
  GET_CITIES_WITHOUT_CHECKPOINT_2,
  GET_DOCS,
} from "../../../../queries/5steps";

import Step1 from "./step-1";
import Step2 from "./step-2";
import Step3 from "./step-3";
import Feedback from "./feedback";
import { QuestionCircleFilled } from "@ant-design/icons";
import moment from "moment";

import { colors } from "../layout/constants/colors";

import citiesMeta from "./cities-meta.json";
import projectCities from "./updatedCities.json";
import CitizensIdeas from "./citizens_ideas";
import Partners from "./partners/partners";

const { useBreakpoint } = Grid;

export const Flex = styled.div`
  display: flex;

  &&::before {
    content: "";
    min-width: 24px;
    height: 24px;
    background: url("/icons/warn1.svg");
    background-size: cover;
    background-position: center;

    margin-right: 10px;
  }
`;

const LinksPace = styled(Space)`
  &&&&& {
    a,
    a * {
      color: ${colors.accent};

      &:hover {
        filter: brightness(1.1);
      }
    }
  }
`;

const CityMeta = styled.div`
  display: flex;
  align-items: flex-start;

  &&::before {
    content: "";
    min-width: 60px;
    height: 60px;
    margin-top: 5px;
    background: url("/icons/city.png");
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin-right: 12px;
  }
`;

const CustomModal = styled(Modal)`
  &&& {
    width: 100% !important;
    max-width: max-content;

    & .ant-modal-body {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 480px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
`;

const FrameWrapper = styled.div`
  width: 720px;
  padding-bottom: 56.25%;
  max-width: 720px;
  position: relative;

  @media (max-width: 772px) {
    width: 560px;
  }

  @media (max-width: 592px) {
    width: calc(100vw - 30px);
  }

  && > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const IdeasWrapper = styled.div`
  width: 100%;
  height: 300px;
  overflow: scroll;
  border-radius: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 10px;

  &&::-webkit-scrollbar {
    display: none;
  }
`;

const IdeasLine = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;

  && > * + * {
    margin-top: 10px;
  }

  && > * {
    padding: 10px;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;

    &,
    & * {
      color: #131c27;
    }
  }
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  && > * + * {
    margin-top: 24px;
  }
`;

const { Title, Text, Paragraph } = Typography;

const Stage = ({ step = "step1", stage01, stage02, stage03, occupation }) => {
  switch (step) {
    case "step1":
      return <Step1 {...{ stage01, stage02 }} />;
    case "step2":
      return <Step2 />;
    case "step3":
      return <Step3 {...{ occupation }} />;
  }
};

const warningUpdateLinksStep3 = [
  "Улан-Удэ",
  "Ясногорск",
  "Новосибирск",
  "Новый Уренгой",
  "Воронеж",
  "Морозовск",
];

const warningUpdateLinksStep2 = [
  //"Благовещенск",
  //"Владимир",
  "Сысерть",
  "Чита",
  //"Гусиноозерск",
  "Ясногорск",
];
const warningUpdateLinks = [
  //"Брянск",
  //"Южно-Сахалинск",
  //"Нальчик",
  "Альметьевск",
  //"Якутск",
  "Волгодонск",
  "Красноярск",
  //"Качканар",
  //"Менделеевск",
  //"Новый Уренгой",
  //"Железноводск",
  //"Суворов",
  //"Ярославль",
  //"Назрань",
  //"Тюмень",
  "Инза",
  //"Светлоград",
  //"Владикавказ",
  "Березники",
];

const HomePage = () => {
  const screens = useBreakpoint();

  const { auth } = useSelector((state) => state);
  const { access_token } = auth;

  const context = {
    headers: {
      authorization: Tokenizer.authHeader(access_token),
    },
  };

  const {
    data: stagesData = {},
    loading: load_stages,
    error: error_stages,
  } = useQuery(STAGES, {
    context,
  });

  /* Берем количество добавленных проектов и заполнена ли анкета */
  const [getSelectedObjs, { data: userSelProjects, loading: loading_usp }] =
    useLazyQuery(GET_SELECTED_OBJECTS, {
      context,
    });
  const [getPerformers, { data: userPerformers, loading: loading_up }] =
    useLazyQuery(GET_PERFORMERS, {
      context,
    });

  const [getCitiesList, { data: citiesList }] = useLazyQuery(
    GET_CITIES_LIST_AS_ANSWERS,
    {
      context,
    }
  );

  const [cityIndex, setCityIndex] = useState(null);

  const {
    data: cityMeta,
    loading: loading_cityMeta,
    error: err_cityMeta,
  } = useQuery(GET_CITY_META, {
    context,
  });

  if (err_cityMeta) console.log("err_cityMeta", err_cityMeta);

  useEffect(() => {
    getSelectedObjs();
    getPerformers();

    getCitiesList();
  }, []);

  const { me = [] } = cityMeta ? cityMeta : {};
  const { meta = {} } = me.length > 0 ? me[0] : {};
  const {
    occupation,
    first_name,
    last_name,
    email,
    city: fullCityname,
    user_id,
  } = meta;

  useEffect(() => {
    if (citiesList && (first_name || last_name)) {
      const { dialog_bot = [] } = citiesList;
      const { voc_link = {} } = dialog_bot.length > 0 ? dialog_bot[0] : {};
      const { voc = {} } = voc_link;
      const { nodes = [] } = voc;

      const { answers = [] } = nodes.length > 0 ? nodes[0] : {};

      let foundIndex = null;

      if (projectCities) {
        projectCities.map((item = {}, i) => {
          const { name = "" } = item;

          if (name.toLowerCase().includes(`${first_name.toLowerCase()} `)) {
            foundIndex = i;
          }
        });
      }

      setCityIndex(foundIndex);
    }
  }, [citiesList, first_name, last_name]);

  const [allowedPerformers, setAllowedPerformers] = useState(false);
  const [allowedProjects, setAllowedProjects] = useState(false);

  const { app5s_usersStages = [] } = stagesData ? stagesData : {};
  const userStages = app5s_usersStages.length > 0 ? app5s_usersStages[0] : {};
  const { stage01, stage02, stage03 } = userStages;

  /* */
  const handlePerformersStates = (performers) => {
    if (performers) {
      const { app5s_userPerformers = [] } = performers ? performers : {};
      const [performer1 = {}, performer2 = {}, performer3 = {}] =
        app5s_userPerformers;

      const { name: fullname1, email: email1 } = performer1;
      const { name: fullname2, email: email2 } = performer2;
      const {
        name: fullname3,
        email: email3,
        position: position3,
      } = performer3;

      const checkCondition = fullname1 && email1 && fullname2 && email2;

      if (checkCondition) {
        setAllowedPerformers(true);
      }
    }
  };

  /* */
  const handleProjectsStates = (response) => {
    const { app5s_userProjectsSelection = [] } = response ? response : {};

    let formattedProjects = app5s_userProjectsSelection;

    let steps = {};
    formattedProjects
      .filter((item = {}) => {
        const { project = {} } = item;
        return project;
      })
      .map((item = {}) => {
        const { project = {} } = item;
        const { step, key, is_priority } = project;

        if (!steps[`step${step}`]) {
          steps[`step${step}`] = { priority: 0, all: 0 };
        }

        if (is_priority) {
          steps[`step${step}`].priority += 1;
        }

        steps[`step${step}`].all += 1;
      });

    let savedSec1;
    let savedSec2;
    let savedSec3;
    let savedSec4;
    let savedSec5;

    const minPrior =
      occupation === "A" || occupation === "B" || occupation === "C" ? 2 : 0;
    const minAll =
      occupation === "A" || occupation === "B" || occupation === "C" ? 3 : 1;

    /* проверка количества выбранных приоритетных и доп проектов */
    if (
      steps[`step1`] &&
      steps[`step1`].priority >= minPrior &&
      steps[`step1`].all >= minAll
    )
      savedSec1 = true;
    if (
      steps[`step2`] &&
      steps[`step2`].priority >= minPrior &&
      steps[`step2`].all >= minAll
    )
      savedSec2 = true;
    if (
      steps[`step3`] &&
      steps[`step3`].priority >= minPrior &&
      steps[`step3`].all >= minAll
    )
      savedSec3 = true;
    if (
      steps[`step4`] &&
      steps[`step4`].priority >= minPrior &&
      steps[`step4`].all >= minAll
    )
      savedSec4 = true;
    if (
      steps[`step5`] &&
      steps[`step5`].priority >= minPrior &&
      steps[`step5`].all >= minAll
    )
      savedSec5 = true;

    if (savedSec1 && savedSec2 && savedSec3 && savedSec4 && savedSec5) {
      return setAllowedProjects(true);
    }

    return;
  };

  /* перевод в функцию */
  useEffect(() => {
    if (userPerformers) {
      handlePerformersStates(userPerformers);
    }
  }, [userPerformers]);

  useEffect(() => {
    if (userSelProjects) {
      handleProjectsStates(userSelProjects);
    }
  }, [userSelProjects]);
  /* */

  const active_step = useMemo(() => {
    if (allowedPerformers && allowedProjects && !loading_up && !loading_usp) {
      return "step3";
    } else {
      return "step1";
    }
  }, [allowedPerformers, allowedProjects, loading_up, loading_usp]);

  const [videoOpened, setVideoOpened] = useState(false);

  const defineDateGroup = (first_name) => {
    /* first_name */
    const foundCity = warningCities.find(({ city }) => city === first_name);

    if (foundCity) {
      return foundCity;
    }

    return {
      time: 10,
      url: "https://calls.mail.ru/room/79f0aacd-664b-4ac3-8cf2-c77c77735a97",
    };
  };

  const { data: userDocs, loading: loadingDocs } = useQuery(GET_DOCS, {
    context,
  });

  const [noSecondFileLink, setNoSecondFileLink] = useState(false);
  //console.log({ userDocs });

  useEffect(() => {
    if (userDocs) {
      const { app5s_userDocs = [] } = userDocs;
      app5s_userDocs.map((item = {}, i) => {
        const { doc_id, checkpoint, file } = item;
        if (checkpoint === 2 && !file) {
          setNoSecondFileLink(true);
        }
      });
    }
  }, [userDocs]);

  return (
    <>
      <CustomModal
        footer={null}
        maskStyle={{ background: "#131C27", opacity: 0.4 }}
        visible={videoOpened}
        onCancel={() => setVideoOpened(false)}
      >
        <FrameWrapper>
          <iframe
            width="100%"
            height="100%"
            src="https://rutube.ru/play/embed/b9aef2a6425c4713e137a87ee55b6f82"
            frameBorder="0"
            allow="clipboard-write; autoplay"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          ></iframe>
        </FrameWrapper>
      </CustomModal>

      <Layout>
        <Row justify="space-between" gutter={[28, 0]}>
          <Col span={screens.lg ? 14 : 24}>
            <Title level={screens.md ? 2 : 3}>Личный кабинет</Title>
          </Col>

          <Col span={screens.lg ? 10 : 24}>
            <CityMeta>
              <Space direction="vertical">
                {loading_cityMeta ? (
                  <Skeleton.Input
                    active
                    style={{ width: "110px", height: "32px" }}
                  />
                ) : (
                  <Title level={4} data-margin="nomargin">
                    {first_name}
                  </Title>
                )}
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  align="center"
                >
                  {loading_cityMeta ? (
                    <Skeleton.Input
                      active
                      style={{ width: "70px", height: "20px" }}
                    />
                  ) : (
                    <>
                      <Text level="1">Группа {occupation} </Text>

                      <Tooltip
                        placement="bottom"
                        title={
                          <>
                            Города-участники Программы распределены по
                            потенциалу реализации проектов на группы. От
                            принадлежности к той или иной группе зависят целевые
                            показатели проектов. Здесь указано, к какой группе
                            отнесен Ваш город.
                          </>
                        }
                      >
                        <QuestionCircleFilled
                          style={{ paddingLeft: "8px", color: "#8DA6D3" }}
                        />
                      </Tooltip>
                    </>
                  )}
                </div>
              </Space>
            </CityMeta>
          </Col>
        </Row>
        <Gap height="1.4rem" />

        <Row gutter={[28, 0]}>
          <Col span={screens.lg ? 14 : 24}>
            {load_stages && (
              <Paper>
                <Skeleton active />
              </Paper>
            )}
            {active_step && !load_stages && (
              <Stage
                step={active_step}
                {...{ stage01, stage02, stage03, occupation }}
              />
            )}
          </Col>

          <Col span={screens.lg ? 10 : 24}>
            {noSecondFileLink && (
              <Paper type="">
                <Flex>
                  <Space direction="vertical">
                    <Paragraph level="1" style={{ marginBottom: "0px" }}>
                      Необходимо загрузить отчет по Контрольной точке 2 до конца
                      октября.
                      <br />
                      <br />
                      В случае возникновения вопросов просим связаться с нами
                      по электронной почте{" "}
                      <a
                        href={"mailto:kmezenina@strelka-kb.com"}
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        kmezenina@strelka-kb.com
                      </a>{" "}
                      или в чате Программы в вотсапе.
                    </Paragraph>
                  </Space>
                </Flex>
              </Paper>
            )}
            {(warningUpdateLinksStep2.includes(first_name) ||
              warningUpdateLinks.includes(first_name)) && (
              <Paper type="warning">
                <Flex>
                  <Space direction="vertical">
                    {warningUpdateLinksStep2.includes(first_name) && (
                      <Paragraph level="1" style={{ marginBottom: "0px" }}>
                        Отчет по контрольной точке 2 необходимо актуализировать
                        (файл не скачивается / пустой). Просим до конца октября
                        обновить файл на платформе, предоставив рабочую ссылку.
                        <br />
                        <br />
                        После обновления ссылки на отчетные материалы просим
                        написать об этом на электронную почту{" "}
                        <a
                          href={"mailto:kmezenina@strelka-kb.com"}
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          kmezenina@strelka-kb.com
                        </a>{" "}
                        или в чат Программы в вотсапе.
                        <br />
                        <br /> Спасибо!
                      </Paragraph>
                    )}
                    {warningUpdateLinks.includes(first_name) && (
                      <Paragraph level="1" style={{ marginBottom: "0px" }}>
                        Отчёты по контрольным точкам необходимо актуализировать
                        (файлы не скачиваются / пустые). Просим до конца октября
                        обновить фалйы на платформе, предоставив рабочую ссылку.
                        <br />
                        <br />
                        После обновления ссылки на отчетные материалы просим
                        написать об этом на электронную почту{" "}
                        <a
                          href={"mailto:kmezenina@strelka-kb.com"}
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          kmezenina@strelka-kb.com
                        </a>{" "}
                        или в чат Программы в вотсапе.
                        <br />
                        <br /> Спасибо!
                      </Paragraph>
                    )}
                  </Space>
                </Flex>
              </Paper>
            )}

            {warningUpdateLinksStep3.includes(first_name) && (
              <Paper type="warning">
                <Flex>
                  <Space direction="vertical">
                    {warningUpdateLinksStep3.includes(first_name) && (
                      <Paragraph level="1" style={{ marginBottom: "0px" }}>
                        Внимание! Отчет по КТ3 недоступен. Просьба проверить
                        корректность ссылки на файл и возможность доступа
                        к нему.
                        <br />
                        <br />
                        После обновления ссылки на отчетные материалы просим
                        написать об этом в чат Программы в вотсапе или
                        на электронную почту{" "}
                        <a
                          href={"mailto:kmezenina@strelka-kb.com"}
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          kmezenina@strelka-kb.com
                        </a>
                        .
                        <br />
                        <br /> Спасибо!
                      </Paragraph>
                    )}
                  </Space>
                </Flex>
              </Paper>
            )}

            {
              <Paper type="warning">
                <Flex>
                  <Space direction="vertical">
                    <Paragraph level="1" style={{ marginBottom: "0px" }}>
                      Присоединяйтесь к чату программы в WhatsApp (ссылка под
                      окном с формой обратной связи)
                    </Paragraph>
                  </Space>
                </Flex>
              </Paper>
            }

            {/* Идеи для горожан */}
            {!loading_cityMeta && user_id && (
              <Partners
                {...{ cityIndex, context, first_name, last_name, user_id }}
              />
            )}

            {/* Идеи для горожан */}
            {cityIndex && <CitizensIdeas {...{ cityIndex, context }} />}

            <Paper>
              <Title level={4}>Материалы для&nbsp;скачивания</Title>

              <LinksPace direction="vertical" size={10}>
                <a href="/files/5_steps_book_12082022.pdf" target="_blank">
                  <Space align="flex-start">
                    <DownloadOutlined />
                    <Text level="1" style={{ lineHeight: "1.2" }}>
                      Программа (43.7мб)
                    </Text>
                  </Space>
                </a>
                <a href="/files/220513_5steps_vovlechenie.pdf" target="_blank">
                  <Space align="flex-start">
                    <DownloadOutlined />
                    <Text level="1" style={{ lineHeight: "1.2" }}>
                      Методические рекомендации по вовлечению горожан (PDF,
                      4.4мб)
                    </Text>
                  </Space>
                </a>
                <a href="/files/220513_5steps_budget_2.pdf" target="_blank">
                  <Space align="flex-start">
                    <DownloadOutlined />
                    <Text level="1" style={{ lineHeight: "1.2" }}>
                      Методические рекомендации по поиску источников
                      финансирования (9мб)
                    </Text>
                  </Space>
                </a>
              </LinksPace>
            </Paper>

            <Feedback email={email} cityname={fullCityname} />

            <Paper>
              <Row>
                <Col span={14}>
                  <Title level={5}>Чат программы</Title>
                  <Text level="1" style={{ lineHeight: "1.2" }}>
                    Присоединяйтесь к чату Программы в WhatsApp по ссылке:
                    <br />
                    <a
                      style={{ color: colors.accent }}
                      href="https://chat.whatsapp.com/JO6mJLhPdfSEf3hWLv5NjI"
                      target="_blank"
                    >
                      https://chat.whatsapp.com/JO6mJLhPdfSEf3hWLv5NjI
                    </a>
                  </Text>
                </Col>

                <Col
                  span={10}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div
                    style={{
                      minWidth: "150px",
                      width: "150px",
                      height: "150px",
                      backgroundColor: "grey",
                      backgroundImage: 'url("/qr/qrcode.png")',
                      backgroundSize: "cover",
                    }}
                  ></div>
                </Col>
              </Row>
            </Paper>
            <Paper>
              <Row>
                <Col span={14}>
                  <Title level={5}>Сообщество программы в ВК</Title>
                  <Text level="1" style={{ lineHeight: "1.2" }}>
                    Присоединяйтесь к сообществу по ссылке:
                    <br />
                    <a
                      style={{ color: colors.accent }}
                      href="https://vk.com/5stepsforcities"
                      target="_blank"
                    >
                      https://vk.com/5stepsforcities
                    </a>
                  </Text>
                </Col>

                <Col
                  span={10}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div
                    style={{
                      minWidth: "150px",
                      width: "150px",
                      height: "150px",
                      backgroundColor: "grey",
                      backgroundImage: 'url("/qr/vkqr.png")',
                      backgroundSize: "cover",
                    }}
                  ></div>
                </Col>
              </Row>
            </Paper>

            <a
              href="https://rutube.ru/video/private/b9aef2a6425c4713e137a87ee55b6f82/?p=aqSEzARGcHOh19RxCeHLsA"
              target="_blank"
            >
              <Paper
                onClick={() => setVideoOpened(true)}
                style={{ cursor: "pointer" }}
              >
                <div
                  size={16}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "80%",
                  }}
                >
                  <svg
                    style={{
                      width: "60px",
                      height: "60px",
                      marginRight: "16px",
                    }}
                    width="168"
                    height="168"
                    viewBox="0 0 168 168"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="84" cy="84" r="84" fill="#1350A0" />
                    <path d="M130 84.5L57 41V127.5L130 84.5Z" fill="white" />
                  </svg>

                  <Title level={5}>
                    Посмотрите обучающее видео о&nbsp;Программе
                  </Title>
                </div>
              </Paper>
            </a>

            <Paper
              onClick={() => setVideoOpened(true)}
              style={{ cursor: "pointer" }}
            >
              <a
                href="https://rutube.ru/video/private/543e1332baafe4df81ed6b86f55e3a67/?p=HNZLT_rXu_QCtg3h4MZwzA"
                target="_blank"
              >
                <div
                  size={16}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "80%",
                  }}
                >
                  <svg
                    style={{
                      width: "60px",
                      height: "60px",
                      marginRight: "16px",
                    }}
                    width="168"
                    height="168"
                    viewBox="0 0 168 168"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="84" cy="84" r="84" fill="#1350A0" />
                    <path d="M130 84.5L57 41V127.5L130 84.5Z" fill="white" />
                  </svg>

                  <Title level={5}>
                    Посмотреть запись онлайн-семинара от&nbsp;23 сентября
                  </Title>
                </div>
              </a>

              <br />
              <LinksPace direction="vertical" size={10}>
                <a href="/files/seminar_presentation.pdf" target="_blank">
                  <Space align="flex-start">
                    <DownloadOutlined />
                    <Text level="1" style={{ lineHeight: "1.2" }}>
                      Презентация к онлайн семинару (1.8мб)
                    </Text>
                  </Space>
                </a>
                <a href="/files/seminar_questions.pdf" target="_blank">
                  <Space align="flex-start">
                    <DownloadOutlined />
                    <Text level="1" style={{ lineHeight: "1.2" }}>
                      Вопросы к онлайн семинару (70кб)
                    </Text>
                  </Space>
                </a>
              </LinksPace>
            </Paper>
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default HomePage;

/* <Title level={1}>Заголовок 48</Title>
      <Title level={2}>Заголовок 36</Title>
      <Title level={3}>Заголовок 30</Title>
      <Title level={4}>Заголовок 24</Title>
      <Title level={5}>Заголовок 18</Title>

      <Paragraph level="1">Helllo</Paragraph>
      <Paragraph level="-1">Helllo</Paragraph>
      <Text level="-1">Link</Text> */
