import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const upload = (file, bearer, onUploadProgress) => {
  let formData = new FormData();
  const imageNewName = uuidv4();

  const forDotArr = file.name.split(".");

  const imageType = forDotArr[forDotArr.length - 1];
  const name = `${imageNewName}.${imageType}`;

  const newFile = new File([file], name);

  const http = axios.create({
    baseURL: "https://api.chg.one/v1/file/crowdsourcing",
    headers: {
      "Content-type": "application/json",
      Authorization: bearer,
    },
  });

  formData.append("file", newFile);
  return http.post("/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: bearer,
    },
    onUploadProgress,
  });
};

export default {
  upload,
};
