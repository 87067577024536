import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Paper from "../../layout/paper";
import { colors } from "../../layout/constants/colors";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_DOCS,
  GET_SELECTED_OBJECTS_WITH_STEPS,
  POST_DOCS,
} from "../../../../../queries/5steps";
import Tokenizer from "../../../../../helpers/tokenizer";

import { FileAddOutlined, DownloadOutlined } from "@ant-design/icons";
import Thumb from "../upload";

import {
  Space,
  Typography,
  Button,
  Row,
  Col,
  Grid,
  notification,
  Tooltip,
  Skeleton,
  Form,
  Input,
  Select,
} from "antd";
import { VerticalSpace, BtnSpace } from "../step-1";
import { Link } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";
import { InfoLabel } from "../../request/section";
import { Gap } from "../../layout";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const { Title, Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const successUpload = () => {
  notification.success({
    message: `Успешно сохранено!`,
    placement: "bottomRight",
  });
};

const TargetLabel = styled.div`
  display: flex;
  padding: 2px 5px;
  background: #d6d6d6;
  border-radius: 10px;
  font-size: 11px;
  display: flex;
  align-items: center;
  cursor: pointer;

  && > * + * {
    padding-left: 6px;
  }

  margin-left: 6px;

  &&,
  && * {
    color: #5f5f5f !important;
  }
`;

const errorUpload = () => {
  notification.error({
    message: `Ошибка на сервере`,
    placement: "top",
  });
};

const selectedData = [
  {
    color: "rgba(28, 178, 172, 1)",
    name: "1 Шаг. Город рядом",
    selected:
      "Подъезды жилых домов*; Площадки для собак*; Витрины продуктовых магазинов",
  },
  {
    color: "rgba(65, 178, 86, 1)",
    name: "2 Шаг. Сохрани планету",
    selected:
      "Подъезды жилых домов*; Площадки для собак*; Витрины продуктовых магазинов",
  },
  {
    color: "rgba(253, 133, 36, 1)",
    name: "3 Шаг. Вдохни жизнь",
    selected:
      "Подъезды жилых домов*; Площадки для собак*; Витрины продуктовых магазинов",
  },
  {
    color: "rgba(42, 157, 214, 1)",
    name: "4 Шаг. Добавь энергии",
    selected:
      "Подъезды жилых домов*; Площадки для собак*; Витрины продуктовых магазинов",
  },
  {
    color: "rgba(134, 101, 246, 1)",
    name: "5 Шаг. Собери своих",
    selected:
      "Подъезды жилых домов*; Площадки для собак*; Витрины продуктовых магазинов",
  },
];

const SectionWrapper = styled(Space)`
  &&&&&& * {
    color: ${({ color }) => (color ? color : colors.black)};
  }
`;

const ControlPointWrapper = styled(Space)`
  border-top: 1px solid rgba(201, 203, 206, 1);
  padding-top: 14px;
  padding-bottom: 14px;
  width: 100%;
  position: relative;

  &&[data-border="hidden"] {
    border-top: 1px solid rgba(201, 203, 206, 0);
  }

  &[data-mode="disabled"] {
    filter: grayscale(1);
  }
`;

export const ALink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  && *,
  && {
    color: ${colors.accent};
  }

  &&:hover,
  &&:hover & {
    color: ${colors.accent};
    filter: brightness(1.2);
  }

  & > span:first-child {
    padding-right: 8px;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: #f1f2f3;
  opacity: 0.65;
  z-index: 99;
  position: absolute;
`;

const IdeasAccountWrapper = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  border: 4px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &&:hover {
    border: 4px solid rgba(0, 0, 0, 0.3);
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledForm = styled(Form)`
  &&& .ant-row {
    margin-bottom: 16px;
  }

  &&&& {
    width: 100%;
    max-width: 400px;
  }

  &&&&&&&& input,
  &&&&&&&& .ant-select-selector,
  &&&&&&&& .ant-select-selection-search-input {
    height: 44px;
  }

  &&&&&&&&&& .ant-select-selection-item {
    padding-top: 5px;
    color: #131c27;
  }
`;

const IdeasAccounts = ({ screens }) => {
  const data = [
    {
      step: "Шаг 1",
      title: "Собачьи площадки",
      description:
        "Проект «Собачьи площадки» основан на идее жителя с указанием конкретной локации в городе (ул. Ленина, д. 3).",
    },
    {
      step: "Шаг 5",
      title: "Дворовые соревнования",
      description:
        "Проект «Дворовые соревнования» выбран на основе поступивших идей жителей в количестве 35 шт.",
    },
    {
      step: "Шаг 2",
      title: "Переработка текстиля",
      description:
        "Собственный проект «Переработка текстиля» выбран и разработан на основе предложения жителя о развитии уже существующего в городе решения.",
    },
  ];

  return (
    <>
      {data.map((item = {}, i) => {
        const { step, title, description } = item;

        return (
          <Row key={`item:${i}`}>
            <Col span={screens.md ? 21 : 24}>
              <ControlPointWrapper direction="vertical" size="0">
                <div>
                  <Paragraph level="1">
                    <b style={{ letterSpacing: ".5px" }}>Шаг:</b> {step}
                  </Paragraph>
                </div>
                <div>
                  <Paragraph level="1">
                    <b style={{ letterSpacing: ".5px" }}>Название проекта:</b>{" "}
                    {title}
                  </Paragraph>
                </div>
                <div>
                  <Paragraph level="1">
                    <b style={{ letterSpacing: ".5px" }}>
                      Описание учёта идей горожан:
                    </b>{" "}
                    {description}
                  </Paragraph>
                </div>
              </ControlPointWrapper>
            </Col>
          </Row>
        );
      })}

      <AddIdeasAccount screens={screens} addMore={data.length > 0} />
    </>
  );
};

const AddIdeasAccount = ({ screens, addMore = false }) => {
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <>
      <Row>
        {!visible && (
          <Col span={screens.md ? 21 : 24}>
            <Button
              type="primary"
              data-height={!screens.sm ? "max-content" : "auto"}
              onClick={() => setVisible(true)}
            >
              <BtnSpace>
                <span>
                  {addMore ? "Добавить еще проект" : "Добавить проект"}
                </span>
              </BtnSpace>
            </Button>
          </Col>
        )}

        {visible && (
          <>
            <Col span={screens.md ? 21 : 24}>
              <div
                style={{ borderTop: "1px solid grey", marginBottom: "24px" }}
              ></div>
              <StyledForm form={form} onFinish={onFinish} layout="vertical">
                <b
                  style={{
                    color: "#001520",
                    fontSize: "20px",
                    paddingBottom: "24px",
                  }}
                >
                  Форма добавления нового проекта
                </b>

                <Form.Item
                  name="step"
                  label="Шаг"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select placeholder="Выберите шаг" onChange={() => {}}>
                    <Option value="male">1 Шаг. Город рядом</Option>
                    <Option value="female">2 Шаг. Сохрани планету</Option>
                    <Option value="other">3 Шаг. Вдохни жизнь</Option>
                    <Option value="other">4 Шаг. Добавь энергии</Option>
                    <Option value="other">5 Шаг. Собери своих</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="name"
                  label="Название проекта"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="description"
                  label="Описание учёта идей горожан"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Добавить
                  </Button>
                </Form.Item>
              </StyledForm>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

const ControlPoint = ({
  loading,
  name = "Контрольная точка",
  bearer,
  thumbData,
  setThumbData,
  label = "",
  deadline = "",
  handleDBUpload,
  setAllowToUpload = () => {},
  disabled = false,
  template = "/files/5steps_report1.pptx",
  removeTopLine = false,
}) => {
  const screens = useBreakpoint();

  return (
    <ControlPointWrapper
      data-border={removeTopLine ? "hidden" : "default"}
      data-mode={disabled ? "disabled" : "default"}
      direction="vertical"
      size={15}
    >
      {disabled && <Overlay />}

      <Title level={5} data-margin="nomargin">
        {name}
      </Title>

      <div style={{ marginTop: "-10px" }} level={5} data-margin="nomargin">
        <i style={{ fontSize: "14px" }}>{deadline}</i>
      </div>

      <Space
        size={screens.md ? 30 : 10}
        direction={screens.md ? "horizontal" : "vertical"}
        style={{ position: "relative" }}
      >
        {template && (
          <ALink onClick={() => window.open(template, "_blank")}>
            <DownloadOutlined />
            Шаблон отчета
          </ALink>
        )}

        {loading && (
          <Skeleton.Input active style={{ width: "200px", height: "40px" }} />
        )}

        {!loading && (
          <Thumb
            key={`thumb${label}`}
            {...{
              thumbData,
              setThumbData,
              bearer,
              handleDBUpload,
              setAllowToUpload,
            }}
          />
        )}
      </Space>
    </ControlPointWrapper>
  );
};

const Step3 = ({ occupation }) => {
  const screens = useBreakpoint();

  const [thumbData0, setThumbData0] = useState(null);
  const [thumbData1, setThumbData1] = useState(null);
  const [thumbData2, setThumbData2] = useState(null);
  const [thumbData3, setThumbData3] = useState(null);
  const [thumbData4, setThumbData4] = useState(null);

  const [allowToUpload, setAllowToUpload] = useState(false);

  const { auth } = useSelector((state) => state);
  const context = {
    headers: {
      authorization: Tokenizer.authHeader(auth.access_token),
    },
  };
  const { data: userSelProjects, loading: loading_selProjects } = useQuery(
    GET_SELECTED_OBJECTS_WITH_STEPS,
    {
      context,
    }
  );
  const { data: userDocs, loading: loadingDocs } = useQuery(GET_DOCS, {
    context,
  });
  const [postDocs, { error: docsError, data: docsMutateData }] = useMutation(
    POST_DOCS,
    {
      context,
      onCompleted: () => {},
      onError: errorUpload,
    }
  );

  //if (docsMutateData) console.log("docsMutateData", docsMutateData);

  useEffect(() => {
    if (userDocs) {
      const { app5s_userDocs = [] } = userDocs;

      app5s_userDocs.map((item = {}, i) => {
        const { doc_id, checkpoint, file } = item;

        if (checkpoint === 0) setThumbData0(file);
        if (checkpoint === 1) setThumbData1(file);
        if (checkpoint === 2) setThumbData2(file);
        if (checkpoint === 3) setThumbData3(file);
        if (checkpoint === 4) setThumbData4(file);
      });
    }
  }, [userDocs]);

  useEffect(() => {
    if (allowToUpload) {
      postDocs({
        variables: {
          objects: [
            { file: thumbData0, checkpoint: 0 },
            { file: thumbData1, checkpoint: 1 },
            { file: thumbData2, checkpoint: 2 },
            { file: thumbData3, checkpoint: 3 },
            { file: thumbData4, checkpoint: 4 },
          ],
        },
      });

      successUpload();

      setAllowToUpload(false);
    }
  }, [
    allowToUpload,
    thumbData0,
    thumbData1,
    thumbData2,
    thumbData3,
    thumbData4,
  ]);

  const handleDBUpload = () => {};

  /* */
  const bearer = Tokenizer.authHeader(auth.access_token);
  /* */

  const [selectedProjects, setSelectedProjects] = useState([]);

  useEffect(() => {
    if (userSelProjects) {
      const { app5s_userProjectsSelection = [], app5s_projects = [] } =
        userSelProjects;

      const filteredAllProjects = app5s_userProjectsSelection
        .filter((item = {}) => {
          const { project = {} } = item;
          return project;
        })
        .filter(({ project = {} }) => {
          const { owner_user_id } = project;

          return !owner_user_id;
        });

      const filteredOwnProjects = app5s_projects.map(
        ({
          id,
          key,
          is_priority,
          owner_user_id,
          step,
          is_approved,
          meta = {},
          targetA = "",
          targetB = "",
          targetC = "",
          targetD = "",
        }) => {
          const { title } = meta;

          return {
            projectd_id: id,
            project: {
              key: title,
              is_priority,
              is_approved,
              owner_user_id,
              step,
              targetA,
              targetB,
              targetC,
              targetD,
            },
          };
        }
      );

      setSelectedProjects([...filteredAllProjects, ...filteredOwnProjects]);
    }
  }, [userSelProjects]);

  return (
    <>
      <Paper>
        <Title level={4}>Ваши проекты</Title>

        <Space direction="vertical" size={12}>
          {selectedProjects &&
            !loading_selProjects &&
            selectedData.map(({ name, selected, color }, i) => {
              const step_index = i + 1;
              const selectedItems = selectedProjects
                .filter(({ project = {} }) => {
                  const { step = 0 } = project;
                  return step === step_index;
                })
                .sort(({ project: p1 = {} }, { project: p2 = {} }) => {
                  const { is_priority: prior1, owner_user_id: owner1 } = p1;
                  const { is_priority: prior2, owner_user_id: owner2 } = p2;

                  if (prior1 && !prior2) return -1;
                  if (!prior1 && prior2) return 1;
                  if (owner1 && !owner2) return 1;
                  if (!owner1 && owner2) return -1;

                  return 0;
                })
                .map(({ project = {} }) => {
                  const {
                    key,
                    is_priority,
                    is_approved,
                    targetA,
                    targetB,
                    targetC,
                    targetD,
                    owner_user_id,
                  } = project;

                  return (
                    <div
                      level="1"
                      style={{
                        lineHeight: "1.4",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      {`${key}`}
                      {!is_approved ? <i> (на рассмотрении)</i> : ""}
                      {`${is_priority ? "*" : ""} `}
                      {/*!owner_user_id &&
                        `${
                          occupation === "A"
                            ? `(${targetA})`
                            : occupation === "B"
                            ? `(${targetB})`
                            : occupation === "C"
                            ? `(${targetC})`
                            : occupation === "D" || occupation === "E"
                            ? `(${targetD})`
                            : `(${targetA})`
                        }`*/}
                      {!owner_user_id && occupation && (
                        <Tooltip
                          placement="top"
                          title={
                            occupation === "A"
                              ? targetA
                              : occupation === "B"
                              ? targetB
                              : occupation === "C"
                              ? targetC
                              : occupation === "D" || occupation === "E"
                              ? targetD
                              : ""
                          }
                        >
                          <TargetLabel>
                            <InfoCircleOutlined />{" "}
                            <span>Целевой показатель</span>
                          </TargetLabel>
                        </Tooltip>
                      )}
                    </div>
                  );
                });

              return (
                <SectionWrapper
                  key={`section::${i}`}
                  color={color}
                  direction="vertical"
                  size={0}
                >
                  <Title level={5} data-margin="nomargin">
                    {name}
                  </Title>
                  <Gap height=".5rem"></Gap>
                  <Space direction="vertical">{selectedItems}</Space>
                </SectionWrapper>
              );
            })}
          {loading_selProjects && <Skeleton />}
        </Space>
      </Paper>

      <InfoLabel style={{ maxWidth: "max-content" }}>
        <span style={{ fontSize: "12px" }}>
          Проекты можно редактировать до 30.09.2022
        </span>
      </InfoLabel>

      <VerticalSpace style={{ marginBottom: "48px" }}>
        <Link to="/executors">
          <Button
            type="primary"
            data-height={!screens.sm ? "max-content" : "auto"}
          >
            <BtnSpace>
              <span>{"Изменить информацию об ответственных лицах"}</span>

              <div style={{ marginLeft: "12px", marginTop: "-2px" }}>
                <CaretRightOutlined />
              </div>
            </BtnSpace>
          </Button>
        </Link>

        <Link to="/request">
          <Button
            type="primary"
            data-height={!screens.sm ? "max-content" : "auto"}
          >
            <BtnSpace>
              <span>{"Изменить выбор проектов"}</span>

              <div style={{ marginLeft: "12px", marginTop: "-2px" }}>
                <CaretRightOutlined />
              </div>
            </BtnSpace>
          </Button>
        </Link>
      </VerticalSpace>

      <Paper>
        <Title level={4}>Планирование реализации программы</Title>

        <Row>
          <Col span={screens.md ? 21 : 24}>
            <Paragraph level="1">
              Здесь вы можете скачать шаблон графика реализации Программы.
              Заполните этот шаблон, указав ключевые этапы реализации выбранных
              проектов, и отправьте через расположенную ниже форму.
            </Paragraph>

            <Paragraph level="1">
              Прикрепите сюда готовый файл графика реализации Программы (xls)
            </Paragraph>

            <Space
              size={screens.md ? 30 : 10}
              direction={screens.md ? "horizontal" : "vertical"}
              style={{ position: "relative", marginTop: "16px" }}
            >
              <ALink
                onClick={() =>
                  window.open("/files/5steps_timeline_2024.xlsx", "_blank")
                }
              >
                <DownloadOutlined />
                Шаблон отчета
              </ALink>
              <Thumb
                key={"thumb1"}
                {...{
                  thumbData: thumbData0,
                  setThumbData: setThumbData0,
                  handleDBUpload,
                  bearer,
                  setAllowToUpload,
                }}
              />
            </Space>
          </Col>
        </Row>
      </Paper>

      <Paper>
        <Title level={4}>Учет идей горожан при выборе проектов</Title>

        <Row>
          <Col span={screens.md ? 21 : 24}>
            <ControlPoint
              label={"ideas_account1"}
              {...{
                loading: loadingDocs,
                thumbData: thumbData4,
                setThumbData: setThumbData4,
                handleDBUpload,
                bearer,
                setAllowToUpload,
                template: "/files/5steps_ideas.docx",
                removeTopLine: true,
              }}
              name=""
            />
          </Col>
        </Row>
      </Paper>

      <Paper>
        <Title level={4}>Контрольные точки</Title>

        <Row>
          <Col span={screens.md ? 21 : 24}>
            <Paragraph level="1">
              Здесь вы можете скачать шаблоны отчетов по реализации Программы
              для каждой из контрольных точек. После заполнения шаблона
              отправьте его через соответствующую форму
            </Paragraph>

            <ControlPoint
              label={"cp1"}
              {...{
                loading: loadingDocs,
                thumbData: thumbData1,
                setThumbData: setThumbData1,
                handleDBUpload,
                bearer,
                setAllowToUpload,
              }}
              deadline="Срок: 30 ноября 2022 года"
              name="Контрольная точка 1"
            />
            <ControlPoint
              label={"cp2"}
              {...{
                loading: loadingDocs,
                thumbData: thumbData2,
                setThumbData: setThumbData2,
                handleDBUpload,
                bearer,
                setAllowToUpload,
              }}
              template="/files/p/5steps_report2.pptx"
              deadline="Срок: 5 мая 2023 года"
              name="Контрольная точка 2"
            />
            <ControlPoint
              label={"cp3"}
              {...{
                loading: loadingDocs,
                thumbData: thumbData3,
                setThumbData: setThumbData3,
                handleDBUpload,
                bearer,
                setAllowToUpload,
              }}
              template="/files/p/5steps_report3.pptx"
              deadline="Срок: 15 декабря 2023 года"
              name="Контрольная точка 3"
            />
          </Col>
        </Row>
      </Paper>
    </>
  );
};

export default Step3;
