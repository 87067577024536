import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Row, Col, Space, Divider, Typography, Grid, Drawer } from "antd";
import { Container } from "../index";
import { colors } from "../constants/colors";
import { Link } from "react-router-dom";
import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import Tokenizer from "../../../../../helpers/tokenizer";

import { authLogout } from "../../../../../actions/auth";

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 60px;
  background: ${colors.black};
  position: fixed;

  z-index: 99;

  &&&& * {
    color: ${colors.white};
  }
`;

const Signout = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  && > * + * {
    margin-left: 14px;
  }
`;

const MobileDrawer = styled(Drawer)`
  && .anticon {
    color: ${colors.white};
  }

  && .ant-divider {
    border-top: 1px solid #7b868c;
  }

  &&& .ant-drawer-body {
    background: ${colors.black};

    & * {
      color: ${colors.white};
    }
  }
`;

const Header = ({ ...reduxProps }) => {
  const screens = useBreakpoint();

  const { authLogout: logout, auth = {} } = reduxProps ? reduxProps : {};
  const { access_token } = auth;
  const auth_data = Tokenizer.decode(access_token);

  const { username } = auth_data ? auth_data : {};
  const username_label = username ? username : "(Почта отсутствует)";

  const [mobileNav, setMobileNav] = useState(false);

  return (
    <>
      {!screens.md && (
        <MobileDrawer
          title=""
          placement={"top"}
          closable={true}
          onClose={() => setMobileNav(false)}
          visible={mobileNav}
          key={"navigation"}
        >
          <Space size={4} direction="vertical" style={{ width: "100%" }}>
            <Text level="1">{username_label}</Text>

            <Divider type="horizontal" />

            <Signout onClick={logout}>
              <LogoutOutlined />
              <Text level="1">Выйти</Text>
            </Signout>
          </Space>
        </MobileDrawer>
      )}

      <HeaderWrapper>
        <Container $type="header">
          <Row justify="space-between">
            <Col span={!screens.md ? 20 : 16}>
              <Link to="/">
                <Title
                  level={5}
                  data-margin="nomargin"
                  data-size={!screens.sm ? "smaller" : null}
                >
                  Пять шагов для городов
                </Title>
              </Link>
            </Col>

            <Col
              span={!screens.md ? 4 : 8}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {screens.md && (
                <Space size={32}>
                  <Text level="1">{username_label}</Text>

                  <Divider type="vertical" />

                  <Signout onClick={logout}>
                    <LogoutOutlined />
                    <Text level="1">Выйти</Text>
                  </Signout>
                </Space>
              )}

              {!screens.md && (
                <MenuOutlined onClick={() => setMobileNav(true)} />
              )}
            </Col>
          </Row>
        </Container>
      </HeaderWrapper>
    </>
  );
};

export default connect(
  ({ app, auth, settings }) => ({
    app,
    auth,
    settings,
  }),
  { authLogout }
)(Header);
