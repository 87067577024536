/**
 * External Dependencies
 */
import {
  APP_CURRENT_PROJECT,
  GET_MAP_IDEAS,
  APP_PROJECTS,
  APP_TOOLS,
  PUBLISH_IDEA,
  EDIT_IDEA,
  GET_IDEA,
  SET_CURRENT_PROJECT_ID,
  APP_CLEANUP,
  APP_PROJECT_LOADING,
  APP_PROJECT_LOADED,
  GET_IDEA_CATEGORIES,
  ANSWERS_CURRENT_BOT,
} from "../actions/app";

const INITIAL_APP_STATE = {
  projects: [],
  summary: [],
  tools: [],
  dialog: {
    platform: [
      {
        id: 1,
        key: "telegram",
        name: "Telegram",
      },
      {
        id: 2,
        key: "vk",
        name: "VKontakte",
      },
    ],
    bots: [
      {
        id: 1,
        key: "telegram",
        name: "Telegram",
        platform: "Telegram",
      },
      {
        id: 2,
        key: "vk",
        name: "VKontakte",
        platform: "Telegram",
      },
    ],
    currentBot: {
      name: "@beloretsk_crowd_bot",
      chats: [
        {
          id: 11,
          title: "Сергей",
          last_message: "да, и площадки лучше покрасьте!",
          last_time: "16:21",
          state_id: 1,
          users: [
            {
              id: 1,
              name: "Сергей",
            },
          ],
        },
      ],
    },
    currentChat: {
      id: 11,
      title: "Сергей",
      last_message: "да, и площадки лучше покрасьте!",
      state: 1,
      users: [
        {
          id: 1,
          name: "Сергей",
        },
      ],
      messages: [
        {
          id: 1001,
          user: null,
          date: "2020-03-30 11:12",
          message: {
            text: "Что бы вы предложили сделать в городе?",
          },
          is_self: true,
        },
        {
          id: 1002,
          user: {
            id: 1,
            name: "Сергей",
          },
          date: "2020-03-30 11:12",
          message: {
            text: "да, и площадки лучше покрасьте!",
          },
          is_self: false,
        },
      ],
    },
  },
  isLoading: false,
  currentProject: null,
  currentProjectId: null,
  currentIdea: null,
  mapIdeas: [],
  ideaCategories: [],
};

export default function (state = INITIAL_APP_STATE, action) {
  switch (action.type) {
    case APP_PROJECT_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case APP_PROJECT_LOADED:
      return {
        ...state,
        isLoading: false,
      };

    case APP_CLEANUP:
      return INITIAL_APP_STATE;

    case EDIT_IDEA:
    case PUBLISH_IDEA:
      return {
        ...state,
        currentIdea: state.currentIdea
          ? {
              ...state.currentIdea,
              ...action.payload,
            }
          : null,
      };
    case GET_IDEA:
      return {
        ...state,
        currentIdea: action.payload,
      };
    case GET_MAP_IDEAS:
      return {
        ...state,
        mapIdeas: action.payload,
      };
    case APP_PROJECTS:
      return {
        ...state,
        projects: action.payload.projects,
        summary: action.payload.summary,
      };
    case APP_TOOLS:
      return {
        ...state,
        tools: action.payload.tools,
      };
    case GET_IDEA_CATEGORIES:
      return {
        ...state,
        ideaCategories: action.payload?.categories,
      };
    case SET_CURRENT_PROJECT_ID: {
      const localStorageInstance = localStorage || {};
      localStorageInstance["currentProjectId"] = action.payload;
      return {
        ...state,
        currentProjectId: action.payload,
      };
    }
    case APP_CURRENT_PROJECT: {
      const localStorageInstance = localStorage || {};
      localStorageInstance["currentProject"] = action.payload;
      return {
        ...state,
        currentProject: action.payload,
      };
    }
    case ANSWERS_CURRENT_BOT:
      return {
        ...state,
        currentBot: action.payload,
      };
    default:
      return state;
  }
}

// const _example_INITIAL_APP_STATE = {
//   projects: [
//     {
//       id: "bcd83cad-6074-4ac4-ae6f-5dabe21b1b3f",
//       title: "Чего хочет Белорецк"
//     }
//   ],
//   dialog: {
//     platform: [
//       {
//         id: 1,
//         key: "telegram",
//         name: "Telegram"
//       },
//       {
//         id: 2,
//         key: "vk",
//         name: "VKontakte"
//       }
//     ],
//     bots: [
//       {
//         id: 1,
//         key: "telegram",
//         name: "Telegram",
//         platform: "Telegram"
//       },
//       {
//         id: 2,
//         key: "vk",
//         name: "VKontakte",
//         platform: "Telegram"
//       }
//     ],
//     currentBot: {
//       name: "@beloretsk_crowd_bot",
//       chats: [
//         {
//           id: 11,
//           title: "Сергей",
//           last_message: "да, и площадки лучше покрасьте!",
//           last_time: "16:21",
//           state_id: 1,
//           users: [
//             {
//               id: 1,
//               name: "Сергей"
//             }
//           ]
//         }
//       ]
//     },
//     currentChat: {
//       id: 11,
//       title: "Сергей",
//       last_message: "да, и площадки лучше покрасьте!",
//       state: 1,
//       users: [
//         {
//           id: 1,
//           name: "Сергей"
//         }
//       ],
//       messages: [
//         {
//           id: 1001,
//           user: null,
//           date: "2020-03-30 11:12",
//           message: {
//             text: "Что бы вы предложили сделать в городе?"
//           },
//           is_self: true
//         },
//         {
//           id: 1002,
//           user: {
//             id: 1,
//             name: "Сергей"
//           },
//           date: "2020-03-30 11:12",
//           message: {
//             text: "да, и площадки лучше покрасьте!"
//           },
//           is_self: false
//         }
//       ]
//     }
//   },
//   currentProject: {
//     id: "bcd83cad-6074-4ac4-ae6f-5dabe21b1b3f",
//     title: "Чего хочет Белорецк",
//     city: {
//       id_gis: 1,
//       name: "Белорецк",
//       lat: 0,
//       lng: 0
//     },
//     summary: [
//       {
//         title: "Сводка",
//         values: [
//           {
//             type: 1,
//             title: "Активность",
//             value: 219
//           },
//           {
//             type: 1,
//             title: "Пользвоатели",
//             value: 124
//           },
//           {
//             type: 1,
//             title: "Новых",
//             value: 32
//           }
//         ]
//       },
//       {
//         title: "Платформы",
//         values: [
//           {
//             type: 1,
//             title: "Telegram",
//             value: 243
//           },
//           {
//             type: 1,
//             title: "VK",
//             value: 543
//           },
//           {
//             type: 1,
//             title: "Лендинг",
//             value: 211
//           }
//         ]
//       }
//     ],
//     metrics: [
//       {
//         id: "123",
//         title: "Активность",
//         type_id: 1,
//         labels: [
//           "Интервал",
//           "Диалогов",
//           "Новых",
//           "Завершенных",
//           "Глубина",
//           "Конверсия"
//         ],
//         values: Array.from({ length: 40 }, () => {
//           return [
//             "20.03.2020",
//             Math.ceil(Math.random() * 1000),
//             Math.ceil(Math.random() * 1000),
//             Math.ceil(Math.random() * 100),
//             Math.ceil(Math.random() * 20),
//             Math.ceil(Math.random() * 100)
//           ];
//         })
//         // values: Array.from( { length: 40 }, () => Math.floor( ( Math.random() * ( 100 - 40 ) ) + 40 ) )
//       }
//     ],
//     facts: [],
//     ideas: [
//       {
//         id: 217,
//         username: "Драник Сергей",
//         mood: 0.4,
//         date: "20.03.2020 09:22",
//         message:
//           '1. Построить мусороперерабатывающий завод в районе городской свалки. 2. Установить контейнеры по раздельному сбору мусора, особенно в частном секторе. Лучше собирать мусор из контейнеров, чем "героически" чистить свалки на пустырях. 3. Усовершенствовать очистные сооружения города. Исключить сброс неочищенных сточных вод в р.Зея и др. водоемы.',
//         platform: "Telegram",
//         location: {
//           lng: 37.26387,
//           lat: 55.678895,
//           zoom: 17
//         },
//         photos: [
//           {
//             url:
//               "https://чего-хочет-свободный.рф/uploads/photo/image/63/%D0%B7%D0%B0%D0%B2%D0%BE%D0%B4.jpg"
//           }
//         ]
//       },
//       {
//         id: 50,
//         username: "Драник Сергей",
//         mood: 0.8,
//         date: "20.03.2020 09:22",
//         message:
//           'Необходимо сделать качественные ливнёвки. Провести анализ природы (для этого достаточно посмотреть на улицы города во время дождя) происхождения потоков воды после хорошего ливня и предложить сделать в часто подтопляемых местах глубокие, а главное безопасные (чтобы туда не завалился кто-нибудь) ливнёвки, которые поместят в себя достаточное количество воды и смогут её отвести от улиц города и которые можно в последствии спокойно почистить от щебня, гравия, песка и прочих веток, мусора. А Самое главное найти возможность куда эту воду отвести. А не просто в овраг близлежащий направить. Прилагаю фото типичной затопленной улицы. С этим живём десятилетиями. А главное - предлагаю посмотреть во время дождя на самую большую "ливнёвку" - тротуар в Амурской области - около стадиона Локомотив. *рукалицо* - верх инженерной мысли.\n',
//         platform: "Telegram",
//         location: {
//           lng: 37.26387,
//           lat: 55.678895,
//           zoom: 17
//         },
//         photos: [
//           {
//             url:
//               "https://чего-хочет-свободный.рф/uploads/photo/image/15/article_photo_1025-1375436748.jpg"
//           },
//           {
//             url:
//               "https://чего-хочет-свободный.рф/uploads/photo/image/15/article_photo_1025-1375436748.jpg"
//           },
//           {
//             url:
//               "https://чего-хочет-свободный.рф/uploads/photo/image/15/article_photo_1025-1375436748.jpg"
//           },
//           {
//             url:
//               "https://чего-хочет-свободный.рф/uploads/photo/image/15/article_photo_1025-1375436748.jpg"
//           }
//         ]
//       },
//       {
//         id: 104,
//         username: "Сергей",
//         mood: 0.2,
//         date: "20.03.2020 09:22",
//         message:
//           'основная проблема это дыры в дороге, я не могу проехать в центр, не говоря уже об области или "октябрьке", ну просто жуть'
//       },
//       {
//         id: 100,
//         username: "Diana",
//         mood: 0.5,
//         date: "20.03.2020 09:22",
//         message:
//           "У меня есть такая идея, я бы хотела чтобы дорожки были с подогревом, " +
//           "ну хоть каким-нибудь там.. может от теплотрассы, или как еще? " +
//           "Потому что каляску по наледи возить это просто ужас..."
//       }
//     ],
//     dialogs: [
//       {
//         id: 100,
//         username: "Diana",
//         mood: 0.5,
//         date: "20.03.2020 09:22",
//         message:
//           "У меня есть такая идея, я бы хотела чтобы дорожки были с подогревом, " +
//           "ну хоть каким-нибудь там.. может от теплотрассы, или как еще? " +
//           "Потому что каляску по наледи возить это просто ужас..."
//       },
//       {
//         id: 101,
//         username: null,
//         mood: 0,
//         date: "20.03.2020 09:22",
//         message: "Отношение к велодорожкам: отрицательно"
//       },
//       {
//         id: 103,
//         username: "Валерий",
//         mood: 0.4,
//         date: "20.03.2020 09:22",
//         message: "Предлагаю убрать пустырь, лужи стоят постоянно"
//       },
//       {
//         id: 104,
//         username: "Сергей",
//         mood: 0.2,
//         date: "20.03.2020 09:22",
//         message:
//           'основная проблема это дыры в дороге, я не могу проехать в центр, не говоря уже об области или "октябрьке", ну просто жуть'
//       }
//     ],
//     topics: [
//       {
//         title: "мусоросжигающий завод",
//         state_id: 2,
//         stat: {
//           dialog: 12
//         }
//       },
//       {
//         title: "пустырь от стройки",
//         state_id: 1,
//         stat: {
//           dialog: 11
//         }
//       }
//     ]
//   }
// };
