import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const HandleXLSX = ({ data = [], button }) => {
  const formattedData = [...data].map((item = {}) => {
    const { cr, dialogs = [] } = item;

    let meta = {
      /*cr: moment().to(cr)*/
    };

    dialogs.map((t = {}) => {
      const { message = {} } = t;

      const { attachment = {}, node, text } = message;
      const { variant } = attachment;

      if (node === "node0.1") {
        const stepSplit = variant ? variant.split(".") : [];
        const stepIndex = parseInt(stepSplit[stepSplit.length - 1]) + 1;

        meta = { ...meta, step: stepIndex };
      } else if (node === "node0.2") {
        meta = { ...meta, message: text };
      } else if (node === "node0.3") {
        meta = { ...meta, username: text };
      } else if (node === "node0.4") {
        meta = { ...meta, email: text };
      }
    });

    return meta;
  });

  return (
    <ExcelFile element={button}>
      <ExcelSheet data={formattedData} name="Идеи горожан">
        <ExcelColumn label="Шаг" value="step" />
        <ExcelColumn label="Описание" value="message" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default HandleXLSX;
