import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { colors } from "../layout/constants/colors";
import { Link } from "react-router-dom";
import { Gap } from "../layout";

import { InfoCircleOutlined } from "@ant-design/icons";

import {
  Typography,
  Button,
  Row,
  Col,
  Grid as AntGrid,
  Space,
  Tooltip,
  notification,
} from "antd";
import { useEffect } from "react";
const { Title, Text, Paragraph } = Typography;
const { useBreakpoint } = AntGrid;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
`;

const Grid = styled.div`
  width: 100%;
  max-width: 1000px;

  display: grid;

  column-gap: 10px;
  row-gap: 12px;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 992px) and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  cursor: pointer;

  width: 100%;
  padding: 10px;
  background: ${({ active, color }) =>
    active === "active" ? color : colors.lightgrey};

  border-radius: 15px;

  && {
    span.ant-typography[level="1"] {
      line-height: 1.1;
    }
  }

  && {
    span.ant-typography[level="2"] {
      font-size: 0.75rem;
      line-height: 1;
      opacity: 0.45;
      text-decoration: underline;
    }
  }
`;

const Back = styled.div`
  width: 100%;
  background: ${({ fill }) => (fill ? `url("${fill}")` : colors.grey)};
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  position: relative;

  padding-bottom: 63%;
`;

const CardMeta = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  position: relative;

  &&[data-active="active"] {
    & div,
    & span {
      color: white;
    }
  }
`;

const Mark = styled.div`
  position: absolute;
  width: 35px;
  height: 35px;
  background: ${({ color, active }) =>
    active === "active" && color ? color : "white"};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 10px;
  top: 10px;

  ${({ active }) =>
    active === "active"
      ? `
    &&::before {
        content: '';
        background: url('/icons/mark.svg');
        background-size: cover;
        background-position: center;
        width: 21px;
        height: 16px
    }
`
      : ``}
`;

const Priority = styled.div`
  padding: 4px 8px;
  background-color: #ffc700;
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;

  &&::before {
    content: "";
    min-width: 15px;
    height: 14px;
    background: url("/icons/priority.svg");
    background-size: cover;
    background-position: center;
    margin-right: 8px;
  }

  && span.ant-typography {
    font-size: 0.875rem;
  }
`;

const NotApproved = styled.div`
  padding: 4px 8px;
  background-color: lightgrey;
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;

  && span.ant-typography {
    font-size: 0.875rem;
  }
`;

export const InfoLabel = styled.div`
  background: ${colors.lightgrey};
  padding: 3px 6px;
  max-height: 38px;
  border-radius: 10px;
  display: flex;
  align-items: center;

  margin-bottom: 14px;

  &&::before {
    content: "";
    min-width: 24px;
    height: 24px;
    background: url("/icons/warn.svg");
    background-size: cover;
    background-position: center;
    margin-right: 8px;
  }
`;

const handleSelectDeny = () => {
  notification.info({
    message:
      "Добавленный вами проект выбран по умолчанию и не может быть изменен",
    placement: "bottomRight",
  });
};

const Card = (props = {}) => {
  const {
    name = "Название карточки",
    fill = null,
    isPriority,
    isApproved,
    active,
    color,
    onClick = () => {},
    about = "",
    id,
    owner_user_id,
    targetA,
    targetB,
    targetC,
    targetD,
    occupation,
  } = props;

  const handleTarget = (occupation) => {
    switch (occupation) {
      case "A":
        return targetA;
      case "B":
        return targetB;
      case "C":
        return targetC;
      case "D":
        return targetD;
      case "E":
        return targetD;
      default:
        return null;
    }
  };

  let targetMeta = handleTarget(occupation);

  return (
    <CardWrapper
      active={active ? "active" : "noactive"}
      color={color}
      onClick={owner_user_id ? handleSelectDeny : onClick}
    >
      <Back fill={fill}>
        <Mark active={active ? "active" : "noactive"} color={color} />
        {isPriority && (
          <Priority>
            <Text level="1">Приоритетный</Text>
          </Priority>
        )}
        {!isApproved && (
          <NotApproved>
            <Text level="1">На рассмотрении</Text>
          </NotApproved>
        )}
      </Back>

      <CardMeta data-active={active ? "active" : "noactive"}>
        <Row justify="space-between" style={{ width: "100%" }}>
          <Col span={22}>
            <Paragraph
              ellipsis={{ rows: 2 }}
              style={{ maxWidth: "300px", marginBottom: "10px" }}
              level="1"
            >
              {name}
            </Paragraph>
          </Col>

          <Col span={2}>
            <Tooltip placement="top" title={about}>
              <div style={{ transform: "translateY(-6px)", opacity: 0.75 }}>
                <InfoCircleOutlined />
              </div>
            </Tooltip>
          </Col>
        </Row>

        {targetA && (
          <div
            style={{
              lineHeight: "1.1",
              fontSize: ".875rem",
              opacity: 0.75,
              maxWidth: "70%",
            }}
          >
            {/*<span style={{ fontWeight: "500" }}> Целевой показатель:</span>
            <br />*/}
            {targetMeta && (
              <div style={{ paddingTop: "5px", marginBottom: "12px" }}>
                {targetMeta}
              </div>
            )}
          </div>
        )}
      </CardMeta>
    </CardWrapper>
  );
};

const Section = (props = {}) => {
  const screens = useBreakpoint();

  const {
    step_index = 0,
    title = "",
    section_projects = [],
    selectedProjects = [],
    setSelectedProjects,
    finish = () => {},
    occupation,
  } = props;

  const handleReqAll = (occupation) => {
    switch (occupation) {
      case "A":
        return "3 проекта";
      case "B":
        return "3 проекта";
      case "C":
        return "3 проекта";
      case "D":
        return "1 проект";
      case "E":
        return "1 проект";
      default:
        return "1 проект";
    }
  };

  const handleReqPrior = (occupation) => {
    switch (occupation) {
      case "A":
        return 2;
      case "B":
        return 2;
      case "C":
        return 2;
      case "D":
        return 0;
      case "E":
        return 0;
      default:
        return 0;
    }
  };

  const colors = ["#1CB2AC", "#41B256", "#FD8524", "#2A9DD6", "#8665F6"];

  const color = colors[step_index - 1];
  const cards = useMemo(() => {
    if (section_projects) {
      const mainProjects = section_projects
        .filter(({ owner_user_id }) => !owner_user_id)
        .sort(({ ordering: ord0 }, { ordering: ord1 }) =>
          ord0 > ord1 ? 1 : -1
        )
        .map(
          ({
            key,
            is_priority = false,
            thumb,
            about,
            targetA,
            targetB,
            targetC,
            targetD,
            id,
            is_approved,
          }) => {
            return {
              name: key,
              fill: thumb,
              about,
              is_priority,
              is_approved,
              id,
              targetA,
              targetB,
              targetC,
              targetD,
            };
          }
        );

      const ownProjects = section_projects
        .filter(({ owner_user_id }) => owner_user_id)
        .map(({ meta = {}, id, owner_user_id, is_approved }) => {
          const { title, about } = meta;
          return {
            name: title,
            fill: null,
            about,
            id,
            owner_user_id,
            is_approved,
          };
        });

      return [...mainProjects, ...ownProjects];
    }
  }, [section_projects]);

  const activeCount = useMemo(() => {
    const local_projects = selectedProjects.filter(
      ({ step: si }) => step_index === si
    );

    const priority = local_projects.filter(({ is_priority }) => is_priority);

    return [priority.length, local_projects.length - priority.length];
  }, [selectedProjects, step_index]);

  const [activePriority, activeExtra] = activeCount;

  const handleCardClick = (id, step_index, is_priority) => {
    setSelectedProjects((state = []) => {
      const isActive = state.find(({ project_id }) => project_id === id);

      if (isActive) {
        const updated_state = [...state].filter(
          ({ project_id }) => project_id !== id
        );
        return updated_state;
      } else {
        const updated_state = [
          ...state,
          { project_id: id, step: step_index, is_priority },
        ];

        return updated_state;
      }
    });
  };

  const handleActiveStatus = (id) => {
    const isActive = selectedProjects.find(
      ({ project_id }) => project_id === id
    );

    return isActive ? 1 : 0;
  };

  const selectedProjectsInfo = (
    <Text level="2">
      Выбрано проектов: {activePriority + activeExtra}, приоритетных —{" "}
      {activePriority}
    </Text>
  );

  return (
    <>
      <Wrapper id={`sec-wrapper${step_index}`}>
        <Row justify="space-between">
          <Title style={{ color }} level={3}>
            {title}
          </Title>

          <InfoLabel>
            <Text level="2">
              Выберите минимум {handleReqAll(occupation)}
              {handleReqPrior(occupation) > 1 && (
                <>, {handleReqPrior(occupation)} из них — приоритетные</>
              )}
            </Text>
          </InfoLabel>
        </Row>

        <Grid>
          {cards.map((card = {}, i) => {
            const {
              name,
              fill,
              is_priority,
              is_approved,
              about = "",
              id,
              owner_user_id,
              targetA,
              targetB,
              targetC,
              targetD,
            } = card;

            return (
              <Card
                key={`card:${i}`}
                name={name}
                fill={fill}
                active={handleActiveStatus(id)}
                about={about}
                isPriority={is_priority}
                isApproved={is_approved}
                color={color}
                onClick={() => handleCardClick(id, step_index, is_priority)}
                id={id}
                {...{ selectedProjects, setSelectedProjects, owner_user_id }}
                {...{ targetA, targetB, targetC, targetD, occupation }}
              />
            );
          })}
        </Grid>

        <Gap height="1.36rem" />

        {screens.sm && (
          <Row justify="space-between">
            {/*<Button
              type="primary"
              onClick={() => finish(step_index)}
              disabled={activePriority < 2}
            >
              Подтвердить выбор
        </Button>*/}
            {selectedProjectsInfo}
          </Row>
        )}

        {!screens.sm && (
          <Space direction="vertical">
            {selectedProjectsInfo}
            {/*<Button
              type="primary"
              onClick={() => finish(step_index)}
              disabled={activePriority < 2}
            >
              Подтвердить выбор
        </Button>*/}
          </Space>
        )}
      </Wrapper>
    </>
  );
};

export default Section;
