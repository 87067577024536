import React from "react";
import styled from "styled-components";
import { Flex } from "..";
import { Link } from "react-router-dom";
import Paper from "../../layout/paper";

import { Space, Typography, Button, Grid } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { useBreakpoint } = Grid;

export const VerticalSpace = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: max-content;

  @media (max-width: 576px) {
    & {
      max-width: 100%;
    }
  }

  && > *,
  && > * > * {
    min-width: 100%;
    min-height: 64px;
  }

  && > * + * {
    margin-top: 4px;
  }

  margin-bottom: 20px;
`;

export const BtnSpace = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Step1 = ({ stage01, stage02 }) => {
  const screens = useBreakpoint();

  let buttonLabel;

  buttonLabel = (
    <>
      Заполнить информацию
      <br /> об ответственных лицах
    </>
  );
  if (screens.sm)
    buttonLabel = <>Заполнить информацию об ответственных лицах</>;

  return (
    <>
      <Paper type="warning">
        <Flex>
          <Space direction="vertical">
            <Text level="1">
              Чтобы приступить к участию в Программе, вам необходимо:
            </Text>
            <Text level="1">
              <ul>
                {!stage01 && (
                  <li>
                    Заполнить информацию об ответственных лицах до 31.05.2022
                  </li>
                )}
                {!stage02 && <li>Выбрать проекты до 30.09.2022</li>}
              </ul>
            </Text>
            <Text level="1">
              При выборе проектов вы можете добавлять свои проекты, нажав кнопку
              в конце формы. Проекты можно перевыбирать — для этого заполните
              форму повторно и нажмите «Отправить». Ваш финальный список
              проектов будет закреплен 30.09.2022.
            </Text>
          </Space>
        </Flex>
      </Paper>

      <VerticalSpace>
        <Link to="/executors">
          <Button
            type="primary"
            data-height={!screens.sm ? "max-content" : "auto"}
          >
            <BtnSpace>
              <span>{buttonLabel}</span>
              <div style={{ marginLeft: "12px", marginTop: "-2px" }}>
                <CaretRightOutlined />
              </div>
            </BtnSpace>
          </Button>
        </Link>

        <Link to="/request">
          <Button
            type="primary"
            data-height={!screens.sm ? "max-content" : "auto"}
          >
            <BtnSpace>
              <span>Выбрать проекты</span>
              <div style={{ marginLeft: "12px", marginTop: "-2px" }}>
                <CaretRightOutlined />
              </div>
            </BtnSpace>
          </Button>
        </Link>
      </VerticalSpace>
    </>
  );
};

export default Step1;
