import React from "react";
import styled from "styled-components";

import { colors } from "../constants/colors";

const PaperWrapper = styled.div`
  width: 100%;
  padding: 24px 24px;
  border-radius: 10px;
  min-height: 50px;
  position: relative;

  display: flex;
  flex-direction: column;

  margin-bottom: 20px;

  background-color: ${({ type }) =>
    type === "warning" ? colors.yellowish : colors.lightgrey};

  ${({ icon }) =>
    icon
      ? `&&::before {
        content: "";
        background: ${colors.turquoise};
        border-radius: 50%;
    
        position: absolute;
        top: 24px;
        right: 24px;
    
        min-width: 40px;
        width: 40px;
    
        height: 40px;
        min-height: 40px;
      }`
      : ``}
`;

const Paper = ({ children, type, icon = null }) => {
  return (
    <PaperWrapper type={type} icon={icon}>
      {children}
    </PaperWrapper>
  );
};

export default Paper;
