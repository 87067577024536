import React, { useState, useEffect } from "react";
import {
  Progress,
  notification,
  Button,
  Space,
  Grid,
  Input,
  Form,
  Tooltip,
} from "antd";
import styled from "styled-components";
import UploadService from "./upload-service";
import { ALink } from "../step-3";
import { v4 as uuidv4 } from "uuid";
import { LinkOutlined } from "@ant-design/icons";

const ProgressWrapper = styled.div`
  width: 100%;
`;

const LinkFile = styled(Space)`
  && {
    padding: 2px;
    border-radius: 10px;
    cursor: pointer;

    & * {
      color: #13509f;
    }
  }

  && .anticon {
    transform: translateY(-3px);
  }

  &&:hover {
    background: lightgrey;
  }
`;

const SuccessStatus = styled.div`
  background: #9cc8a0;
  display: flex;
  align-items: center;
  padding: 5px;

  border-radius: 3px;
  position: absolute;

  top: -16px;
  font-size: 9px;
  padding: 2px 10px;
  color: black;
`;

import { FileAddOutlined } from "@ant-design/icons";
const { useBreakpoint } = Grid;

const handleFileSizeChecking = (file = {}) => {
  const { size = 0 } = file;
  if (size / 1000 / 1000 < 20) return true;

  return false;
};

const openNotification = (description) => {
  notification.error({
    message: `Ошибка файла`,
    description,
    placement: "bottomRight",
  });
};

const UploadFiles = ({
  thumbData,
  setThumbData,
  bearer,
  handleDBUpload,
  setAllowToUpload,
}) => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [uploadBtn, setUploadBtn] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [preName, setPreName] = useState(null);

  const [heavyFile, setheavyFile] = useState(null);
  const [heavyLink, setHeavyLink] = useState(null);

  const uploadId = uuidv4();

  const screens = useBreakpoint();

  useEffect(() => {
    if (thumbData) {
      setProgress(100);
      setPreName(thumbData);
      setCurrentFile({});
    }
  }, [thumbData]);

  /* */
  const selectFile = (event) => {
    if (!handleFileSizeChecking(event.target.files?.[0])) {
      setheavyFile(true);

      return openNotification("файл не должен превышать 20 Мб");
    }

    if (event.target.files) {
      setPreName(event.target.files?.[0].name);
      setSelectedFiles(event.target.files);
      //setUploadBtn(true);
      //upload(true);
    }
  };
  /* */

  /* */
  useEffect(() => {
    if (selectedFiles && selectedFiles.length > 0) {
      upload(true);
    }
  }, [selectedFiles]);

  const upload = () => {
    let currentFile = selectedFiles[0];

    setProgress(0);
    setCurrentFile(currentFile);
    UploadService.upload(currentFile, bearer, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response = {}) => {
        const { data = [] } = response;
        const { url = "", name = "" } = data && data.length > 0 ? data[0] : {};

        setThumbData(url);
        setAllowToUpload(true);
      })
      .then(() => {
        /*возвращает undefined*/
      })
      .catch((error) => {
        console.log("error", error.message);
        /* выдаст ошибку */
      });
    setSelectedFiles(undefined);
  };
  /* */

  const handleDelete = () => {
    setSelectedFiles(undefined);
    setCurrentFile(undefined);
    setProgress(0);
    setUploadBtn(false);
    setImageUrl(null);
    setPreName(null);
    setThumbData(null);
  };

  const handleName = (name = "") => {
    const l = name.length;
    const max = 20;

    if (l > 10) name = "..." + name.substring(l - max);

    return name;
  };

  return (
    <>
      {(currentFile || thumbData) && progress === 100 && (
        <>
          <SuccessStatus>Файл успешно загружен</SuccessStatus>

          <Space
            size={screens.sm ? 30 : 10}
            direction={screens.sm ? "horizontal" : "vertical"}
          >
            {
              <a href={preName} target="_blank">
                <LinkFile>
                  <LinkOutlined />
                  <span>{handleName(preName)}</span>
                </LinkFile>
              </a>
            }

            <ALink onClick={handleDelete}>Заменить</ALink>
          </Space>
        </>
      )}

      {currentFile && progress < 100 && !imageUrl && (
        <ProgressWrapper>
          <Progress size="small" width={70} percent={progress} />
        </ProgressWrapper>
      )}

      {!currentFile && !imageUrl && (
        <>
          {!uploadBtn && !heavyFile && (
            <label
              style={{ width: "100%", height: "100%", marginBottom: "0px" }}
              htmlFor={"file-upload" + uploadId}
              className="custom-file-upload"
            >
              <Space
                size={screens.sm ? 30 : 10}
                direction={screens.sm ? "horizontal" : "vertical"}
              >
                <ALink>
                  <FileAddOutlined />
                  Прикрепить файл
                </ALink>

                <Button type="primary" disabled data-size="small">
                  Отправить файл
                </Button>
              </Space>
            </label>
          )}

          {!uploadBtn && heavyFile && !heavyLink && (
            <Space
              size={screens.sm ? 30 : 10}
              direction={screens.sm ? "horizontal" : "vertical"}
            >
              <Tooltip
                visible
                title="Файл больше 20 мб. Вы можете загрузить файл на Яндекс Диск или другой файлообменник и прикрепить ссылку на файл"
              >
                {
                  <Button
                    type="primary"
                    style={{ cursor: "pointer" }}
                    data-size="small"
                    onClick={() => setHeavyLink(true)}
                  >
                    {`Прикрепить файл > 20мб`}
                  </Button>
                }
              </Tooltip>
            </Space>
          )}

          {!uploadBtn && heavyFile && heavyLink && (
            <Space
              size={screens.sm ? 30 : 10}
              direction={screens.sm ? "horizontal" : "vertical"}
            >
              {
                <Form
                  onFinish={(e) => {
                    const { url } = e;
                    setThumbData(url);
                    setAllowToUpload(true);
                  }}
                >
                  <Space>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Заполните пожалуйста поле!",
                        },
                      ]}
                      style={{ marginBottom: "0px" }}
                      name="url"
                    >
                      <Input
                        style={{ minWidth: "240px" }}
                        placeholder="Вставьте ссылку для скачивания"
                      />
                    </Form.Item>

                    <Form.Item style={{ marginBottom: "0px" }}>
                      <Button
                        htmlType="submit"
                        type="primary"
                        style={{ cursor: "pointer" }}
                        data-size="small"
                      >
                        {`Прикрепить`}
                      </Button>
                    </Form.Item>
                  </Space>
                </Form>
              }
            </Space>
          )}

          <input
            style={{ display: "none" }}
            id={"file-upload" + uploadId}
            type="file"
            onChange={selectFile}
          />

          {uploadBtn && (
            <Space
              size={screens.sm ? 30 : 10}
              direction={screens.sm ? "horizontal" : "vertical"}
            >
              <ALink>{handleName(preName)}</ALink>

              <Button type="primary" onClick={upload} data-size="small">
                Отправить файл
              </Button>
            </Space>
          )}
        </>
      )}
    </>
  );
};

export default UploadFiles;
