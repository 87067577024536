import styled from "styled-components";

export const IdeasWrapper = styled.div`
  width: 100%;
  height: 300px;
  overflow: scroll;
  border-radius: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 10px;

  &&::-webkit-scrollbar {
    display: none;
  }
`;

export const IdeasLine = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;

  && > * + * {
    margin-top: 10px;
  }

  && > * {
    padding: 10px;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;

    &,
    & * {
      color: #131c27;
    }
  }
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  && > * + * {
    margin-top: 24px;
  }
`;
