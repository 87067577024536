import React, { useMemo, useState } from "react";
import Paper from "../../layout/paper";
import { IdeasLine, IdeasWrapper } from "../citizens_ideas/__styled";
import { Space, Typography, Row, Col, Modal } from "antd";

import data from "./data/data.json";
import { GET_CITY_PARTNERS } from "../../../../../queries/5steps";
import Tokenizer from "../../../../../helpers/tokenizer";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";

const { Title, Text } = Typography;

const Partners = ({ first_name, last_name, user_id }) => {
  const [openedMessage, setOpenedMessage] = useState(false);
  const [messageContent, setMessageContent] = useState(null);

  const { auth } = useSelector((state) => state);
  const { access_token } = auth;

  const context = {
    headers: {
      authorization: Tokenizer.authHeader(access_token),
    },
  };

  const {
    data: partners_d,
    loading: loading_cityMeta,
    error: err_cityMeta,
  } = useQuery(GET_CITY_PARTNERS, {
    context,
    variables: { user_id },
  });

  console.log("partners_d", partners_d);
  if (err_cityMeta) console.log("err_cityMeta", err_cityMeta);

  /* const partners = useMemo(() => {
    if (data) {
      let entries = [];

      let { city = [], region = [], area = [], country = [] } = data;

      try {
        city = [...city].filter(
          ({ city }) =>
            city &&
            first_name &&
            city.toLowerCase().includes(first_name.toLowerCase())
        );

        entries = [...entries, ...city];
      } catch (e) {}

      try {
        region = [...region].filter(
          ({ region }) =>
            region &&
            last_name &&
            region.toLowerCase().includes(last_name.toLowerCase())
        );

        entries = [...entries, ...region];
      } catch (e) {}

      try {
        country = [...country];

        entries = [...entries, ...country];
      } catch (e) {}

      return entries;
    }
  }, [first_name, last_name, data]); */

  const partners = useMemo(() => {
    if (partners_d) {
      const { app_5steps_partners = [] } = partners_d;

      return app_5steps_partners.map((item = {}) => {
        const {
          comment,
          contacts,
          cr,
          dateput,
          fio,
          id,
          interest,
          mail,
          title,
        } = item;

        return {
          index: id,
          cr,
          company: title,
          fio,
          contacts,
          email: mail,
          category: interest,
          comment,
        };
      });
    }
  }, [partners_d]);

  return (
    <>
      <Paper>
        <Title level={4}>Партнеры</Title>

        <Modal
          visible={openedMessage}
          footer={false}
          onCancel={() => setOpenedMessage(false)}
          maskStyle={{ opacity: 0.5 }}
        >
          {messageContent}
        </Modal>

        {partners && (
          <div style={{ marginBottom: "9px" }}>
            <b style={{ color: "#131C27" }}>Всего: {partners.length}</b>
          </div>
        )}

        <IdeasWrapper>
          <IdeasLine>
            {partners &&
              partners.map((item = {}, i) => {
                const { fio, email, contacts, company, category, comments } =
                  item;

                return (
                  <div
                    style={{ cursor: "pointer" }}
                    key={`f:${i}`}
                    onClick={() => {
                      setOpenedMessage(true);
                      setMessageContent(
                        <>
                          <Space direction="vertical" size={20}>
                            <Space direction="vertical" size={2}>
                              <Text>{fio}</Text>
                              <Text>
                                <b>{company}</b>
                              </Text>
                            </Space>

                            <Space direction="vertical" size={2}>
                              <Text style={{ fontSize: "12px" }}>{email}</Text>

                              <Text style={{ fontSize: "12px" }}>
                                {contacts}
                              </Text>
                            </Space>

                            {category && (
                              <Space direction="vertical" size={2}>
                                <Text style={{ fontSize: "12px" }}>
                                  {category}
                                </Text>
                              </Space>
                            )}

                            {comments && (
                              <Space direction="vertical" size={2}>
                                <Text>
                                  <i style={{ fontSize: "12px" }}>
                                    Комментарий: {comments}
                                  </i>
                                </Text>
                              </Space>
                            )}
                          </Space>
                        </>
                      );
                    }}
                  >
                    <Space direction="vertical" size={12}>
                      <Space direction="vertical" size={2}>
                        <Text>{fio}</Text>
                        <Text>
                          <b>{company}</b>
                        </Text>
                      </Space>

                      <Text style={{ color: "#13509F" }}>Подробнее</Text>
                    </Space>
                  </div>
                );
              })}
            {/* */}

            {/* */}
          </IdeasLine>
        </IdeasWrapper>
      </Paper>
    </>
  );
};

export default Partners;
