import React from "react";
import styled from "styled-components";
import { colors } from "../../layout/constants/colors";

const ThumbWrapper = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;

  && > * + * {
    margin-top: 6px;
  }
`;

const ThumbBox = styled.div`
  min-width: 180px;
  max-width: 180px;
  height: 250px;
  position: relative;

  && > .ant-space {
    & {
      max-width: 100%;
    }
  }

  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 0px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadBtn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  color: #131313;
  cursor: pointer;
  border-radius: 20px;
  color: ${colors.accent};
  background-color: white;
  border-radius: 49px;
  border: 2px solid ${colors.accent};
  height: 55px;
  width: max-content;
  padding: 0 50px;

  &&&& * {
    color: ${colors.accent};
  }

  &&:hover {
    &::before {
      filter: brightness(0.9);
    }
  }

  && * {
    font-size: 14px;
  }

  && > * + * {
    margin-top: 8px;
  }
`;

export { ThumbBox, UploadBtn, ThumbWrapper };
