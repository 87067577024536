import React from "react";
import { Flex } from "..";
import Paper from "../../layout/paper";

import { Space, Typography } from "antd";
const { Text } = Typography;

const Step2 = () => {
  return (
    <>
      <Paper type="warning">
        <Flex>
          <Space direction="vertical">
            <Text level="1">
              Ваша заявка на участие в программе находится на рассмотрении
            </Text>
          </Space>
        </Flex>
      </Paper>
    </>
  );
};

export default Step2;
